import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import {
  BannerPictureType,
  FeedItem,
  isFeedItemOfMediaType,
  isFeedItemOfSystemVideoV1Type, isFeedItemOfSystemVideoV2Type, isFeedItemOfUserVideoV2Type,
  Media,
  MediaActionsMode,
} from '@core/models';
import { OverlayService, ShareLinkService } from '@core/services';
import { AvatarComponent, IconComponent } from '@shared/components';
import { RippleDirective, ThrottleClickDirective } from '@shared/directives';
import { ShortNumberPipe } from '@shared/pipes';
import { AnalyticsDirective } from '@shared/directives/analytics.directive';
import { AnalyticsEventsEnum } from '@core/models/analytics.model';
import { SystemVideoV1, UserVideoV2 } from '@api/backend';

@Component({
  selector: 'cheelee-media-actions',
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    IconComponent,
    ShortNumberPipe,
    RippleDirective,
    ThrottleClickDirective,
    AnalyticsDirective,
  ],
  templateUrl: './media-actions.component.html',
  styleUrls: ['./media-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaActionsComponent {

  @Output()
  public openComments = new EventEmitter<void>();

  @Output()
  public closeComments = new EventEmitter<void>();

  @Input()
  public mode: MediaActionsMode = MediaActionsMode.RECOMMENDATIONS;

  @Output()
  public like = new EventEmitter<void>();

  // @ViewChild('avatar', { read: AvatarComponent })
  // private readonly avatar!: AvatarComponent;

  @Input({ required: true })
  public set item(value: FeedItem) {
    if (isFeedItemOfMediaType(value)) {
      this.media.set(value.object);
    } else if (isFeedItemOfSystemVideoV1Type(value) || isFeedItemOfSystemVideoV2Type(value)) {
      this.ad.set(value.object);
    } else if (isFeedItemOfUserVideoV2Type(value)) {
      this.userVideo.set(value.object);
    }
  }

  public media = signal<Media | undefined>(undefined);

  public userVideo = signal<UserVideoV2 | undefined>(undefined);

  public ad = signal<SystemVideoV1 | undefined>(undefined);

  public withLikeAnimation = signal(false);

  public readonly analyticsEvents = AnalyticsEventsEnum;

  constructor(
    private readonly overlayService: OverlayService,
    private readonly shareLinkService: ShareLinkService,
  ) {}


  public openGetAppDialog(action:AnalyticsEventsEnum, picture?: BannerPictureType): void {
    this.overlayService.openGetAppDialog(action, picture);
  }

  public openShareCurtain(mediaId?: string, mediaName?: string): void {
    if (!mediaId) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/naming-convention
    this.shareLinkService.share(mediaId, mediaName || 'Watch Cheelee - get cash | Cheelee', 'media');
  }
}
