/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Invalid discount
 */
export interface TradeInInvalidDiscountError { 
    error_key?: TradeInInvalidDiscountError.ErrorKeyEnum;
}
export namespace TradeInInvalidDiscountError {
    export type ErrorKeyEnum = 'trade_id.invalid_discount';
    export const ErrorKeyEnum = {
        TradeIdInvalidDiscount: 'trade_id.invalid_discount' as ErrorKeyEnum
    };
}


