import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { AnimationOptions, LottieComponent } from 'ngx-lottie';

type LoaderSize = 's' | 'm' | 'l';

@Component({
  selector: 'cheelee-loader',
  standalone: true,
  imports: [ CommonModule, LottieComponent ],
  templateUrl: './loader.component.html',
  styleUrls: [ './loader.component.scss' ],
  host: { '[style.background]': 'background()' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {

  public size = input<LoaderSize>('l');

  public background = input<string>('#140132');

  public options: AnimationOptions = {
    path: './assets/lottie/loader.json',
  };
}
