import { Data, Params } from '@angular/router';
import { RouterNavigationAction } from '@ngrx/router-store';

export interface RouterState {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
  fragment?: string | null;
}

export type NavigationAction = RouterNavigationAction<RouterState>;
