import { ActionReducer, MetaReducer, provideStore } from '@ngrx/store';

import { environment } from '@environments/environment';
import { MediaFeatureProviders } from '@store/media';
import { LayoutFeatureProviders } from '@store/layout';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { SessionFeatureProviders } from '@store/session';
import { UserFeatureProviders } from '@store/user';
import { RouterProviders } from '@store/router/router.feature';
import { AuthFeatureProviders } from '@store/auth';
import { ErrorResponse } from '@core/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

export interface StoreContainer<T> {
  value: T,
  isLoading: boolean,
  error: ErrorResponse | null,
}

export const getInitialContainer = <T>(value: T): StoreContainer<T> => {
  return { value, isLoading: false, error: null };
};

export function debug(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.log('state', state);
      // eslint-disable-next-line no-console
      console.log('action', action);
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [
  // debug,
];

export const storeBaseProviders = [
  provideStore(),
  provideStoreDevtools({ maxAge: 30, logOnly: environment.production }),
  RouterProviders,
  AuthFeatureProviders,
  SessionFeatureProviders,
  MediaFeatureProviders,
  UserFeatureProviders,
  LayoutFeatureProviders,
];
