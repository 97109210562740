/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PurchaseInternal400Response { 
    error_key?: PurchaseInternal400Response.ErrorKeyEnum;
}
export namespace PurchaseInternal400Response {
    export type ErrorKeyEnum = 'offer.invalid_price' | 'wallet.insufficient_funds';
    export const ErrorKeyEnum = {
        OfferInvalidPrice: 'offer.invalid_price' as ErrorKeyEnum,
        WalletInsufficientFunds: 'wallet.insufficient_funds' as ErrorKeyEnum
    };
}


