/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderPaymentMethodGoogle { 
    id?: OrderPaymentMethodGoogle.IdEnum;
    product_id?: string;
}
export namespace OrderPaymentMethodGoogle {
    export type IdEnum = 'google';
    export const IdEnum = {
        Google: 'google' as IdEnum
    };
}


