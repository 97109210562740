/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductInfo } from './productInfo';
import { Video } from './video';
import { Image } from './image';
import { OfferPrice } from './offerPrice';
import { AmountNullable } from './amountNullable';


export interface Offer { 
    id?: string;
    price?: OfferPrice;
    /**
     * Amount of items in the offer. For limited offers only
     */
    count?: number | null;
    /**
     * Short name of the product
     */
    title?: string;
    /**
     * Secondary short name of the product, used for list views
     */
    summary?: string;
    /**
     * Full description of the product. Can contain Markdown
     */
    description?: string | null;
    image?: Image;
    /**
     * Offer expiration date
     */
    expires_at?: string | null;
    product?: ProductInfo;
    reward?: AmountNullable | null;
    video?: Video | null;
}

