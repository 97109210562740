import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare const AF: any;

@Injectable({
  providedIn: 'root',
})
export class AppsFlyerService {

  private readonly APPSFLYER_DEV_KEY = 'ee0f4d10-8b8a-4e21-aa51-0bf4ee70fe4a';


  constructor( ) {
    this.initializeAppsFlyer();
  }

  private initializeAppsFlyer(): void {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.innerHTML = `
        !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
        (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
        t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
        o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
        p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","pba",{pba: {webAppId: "${this.APPSFLYER_DEV_KEY}"}})
      `;
      document.head.appendChild(script);
    }
  }

  public setCustomerUserId(visitorId: string): Observable<string> {
    return new Observable<string>(observer => {
      if (typeof AF === 'undefined') {
        observer.error('Appsflyer SDK not initialized');
        return;
      }

      AF('pba', 'setCustomerUserId', visitorId);

      const uid: string = AF('pba', 'getAfWebUserId');

      if (uid) {
        observer.next(uid);
        observer.complete();
      } else {
        observer.error('AppsFlyer Web User ID not available');
      }

    });
  }
}
