import { Observable } from 'rxjs';
import {
  MarketOffer,
  MarketOfferCurrency,
  MarketOfferPaymentMethod, MarketOfferPaymentMethodPriority,
  MarketOffersPage,
  MarketOfferView, MarketOfferViewModel, MarketPromocodeAction, OrderRequest, OrderResponse,
} from '@core/models';

export abstract class MarketRepository {

  public abstract getOfferViews(): Observable<MarketOfferViewModel[]>;

  public abstract getOffers(pageToken?: string, view?: MarketOfferView): Observable<MarketOffersPage>;

  public abstract getOffer(id: string): Observable<MarketOffer>;

  public abstract getOfferCurrencies(offerId: string): Observable<MarketOfferCurrency[]>;

  public abstract getOfferPaymentMethods(offerId: string, priority: MarketOfferPaymentMethodPriority, preferredCurrency?: string, promocode?: string): Observable<MarketOfferPaymentMethod[]>;

  public abstract getPromocodeDetails(code: string, offerId: string): Observable<MarketPromocodeAction>;

  public abstract purchaseExternal(id: number, body: OrderRequest): Observable<OrderResponse>;
}
