import { PromocodeActionDiscountAbs, PromocodeActionDiscountPercent } from '@api/backend';
import { MarketPromocodeAction } from '@core/models/marketplace.models';

export enum MarketPromoCodeActionType {
  PERCENT = 'discount_percent',
  ABS = 'discount_abs',
}

export type MarketPromoCodeActionPercent = {
  type: MarketPromoCodeActionType.PERCENT;
  object: PromocodeActionDiscountPercent;
};

export type MarketPromoCodeActionAbs = {
  type: MarketPromoCodeActionType.ABS;
  object: PromocodeActionDiscountAbs;
};


export function isMarketPromoCodeActionPercent(
  action: MarketPromocodeAction,
): action is MarketPromoCodeActionPercent {
  return action.type === MarketPromoCodeActionType.PERCENT;
}

export function isMarketPromoCodeActionAbs(
  action: MarketPromocodeAction,
): action is MarketPromoCodeActionAbs {
  return action.type === MarketPromoCodeActionType.ABS;
}


export type MarketPromoCode = {
  id: string;
  action: MarketPromocodeAction,
};
