/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OfferAmount } from './offerAmount';
import { OfferRarity } from './offerRarity';


export interface ProductGlasses { 
    rarity?: OfferRarity;
    max_profit?: OfferAmount;
    /**
     * Remaining watch in seconds
     */
    remaining_watch_duration?: number;
}

