import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { debounceTime, distinct, from, map, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OverlayService, SnackbarService } from '@core/services';
import { NavigationAction, routerActions, routerSelectors } from '@store/router';

@Injectable()
export class RouterEffects {

  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
  ) {}

  public navigate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerActions.navigate),
      distinct(),
      switchMap(({ path, extras }) =>
        from(
          this.router.navigate(Array.isArray(path) ? path : [path], {
            queryParams: extras?.queryParams,
            fragment: extras?.fragment || undefined,
            preserveFragment: false,
            relativeTo: extras?.relativeTo || undefined,
          }),
        ),
      ),
      map(() => routerActions.navigateSuccess()),
    );
  });

  public clearRouterParams$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerActions.clearRouterParams),
      map(() =>
        routerActions.navigate({
          path: [],
          extras: {
            queryParams: null,
            queryParamsHandling: 'merge',
          },
        }),
      ),
    );
  });

  public getBack$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerActions.getBack),
      debounceTime(100),
      concatLatestFrom(() => this.store.select(routerSelectors.selectRouterNavigationId)),
      switchMap(([, navigationId]) => {
        if (navigationId > 0) {
          this.location.back();
          return of(true);
        } else {
          return from(this.router.navigateByUrl('/'));
        }
      }),
      map(() => routerActions.getBackSuccess()),
    );
  });

  public clearOverlaysOnNavigate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<NavigationAction>(ROUTER_NAVIGATION),
        tap(() => {
          this.overlayService.closeAll();
          this.snackbarService.clear();
        }),
      );
    },
    { dispatch: false },
  );

}
