/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetPromocode404Response { 
    error_key?: GetPromocode404Response.ErrorKeyEnum;
}
export namespace GetPromocode404Response {
    export type ErrorKeyEnum = 'promocode.not_found' | 'offer.not_found';
    export const ErrorKeyEnum = {
        PromocodeNotFound: 'promocode.not_found' as ErrorKeyEnum,
        OfferNotFound: 'offer.not_found' as ErrorKeyEnum
    };
}


