/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetOffers500Response { 
    error_key?: GetOffers500Response.ErrorKeyEnum;
}
export namespace GetOffers500Response {
    export type ErrorKeyEnum = 'internal_error';
    export const ErrorKeyEnum = {
        InternalError: 'internal_error' as ErrorKeyEnum
    };
}


