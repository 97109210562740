/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OfferAmount { 
    /**
     * Upper case name of the currency
     */
    currency?: OfferAmount.CurrencyEnum;
    /**
     * Amount of currency as a decimal
     */
    value?: string;
}
export namespace OfferAmount {
    export type CurrencyEnum = 'USD' | 'LEE';
    export const CurrencyEnum = {
        Usd: 'USD' as CurrencyEnum,
        Lee: 'LEE' as CurrencyEnum
    };
}


