import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl<string>): { [key: string]: any } | null => {
    const emailRegex = new RegExp(
      // eslint-disable-next-line max-len
      '^(?![-.])[\\p{L}\\d._-]+@(?:[\\p{L}\\d-]+\\.)+(?:[\\p{L}\\d.-]{2,}|[\\p{L}\\d.-]*[\\p{L}\\d]{1}[\\p{L}\\d.-]*[\\p{L}\\d]{1}[\\p{L}\\d.-]*)$',
      'u',
    );
    const isValid = emailRegex.test(control.value);
    return isValid ? null : { invalidEmail: true };
  };
}
