/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OfferView { 
    /**
     * Unique identifier of the offer view
     */
    id: OfferView.IdEnum;
    /**
     * Title of the offer view
     */
    title: string;
}
export namespace OfferView {
    export type IdEnum = 'digital_goods' | 'lootboxes' | 'glasses' | 'coins';
    export const IdEnum = {
        DigitalGoods: 'digital_goods' as IdEnum,
        Lootboxes: 'lootboxes' as IdEnum,
        Glasses: 'glasses' as IdEnum,
        Coins: 'coins' as IdEnum
    };
}


