import { Offer, OfferPrice, OfferView, PurchaseExternalNoAuthRequest, PurchaseHistoryOrder } from '@api/marketplace';
import { MarketPromoCodeActionAbs, MarketPromoCodeActionPercent } from '@core/models/market-promocode.model';




export type MarketOfferViewModel = OfferView;

export type MarketOfferView = MarketOfferViewModel['id'];

export type MarketOfferPrice = OfferPrice & { final: string };

export type MarketOffer = Offer & { id: string, price: MarketOfferPrice };

export type MarketOffersPage = {
  items: Array<MarketOffer>;
  page_token?: string;
  next_page_token?: string;
  prev_page_token?: string;
};


export type MarketPromocodeAction = MarketPromoCodeActionPercent | MarketPromoCodeActionAbs;

export type MarketOfferDescription = {
  text: string;
  type: 'main' | 'list' | 'note';
};

export type OrderResponse = {
  payment_url: string,
  order: PurchaseHistoryOrder,
};

export type OrderRequest = PurchaseExternalNoAuthRequest;

