import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, AuthState } from '@store/auth/auth.reducer';

export const selectAuthFeature = createFeatureSelector<AuthState>(authFeatureKey);

export const selectEmail = createSelector(
  selectAuthFeature,
  (state) => state.email,
);
export const selectHash = createSelector(
  selectAuthFeature,
  (state) => state.hash,
);
export const selectCode = createSelector(
  selectAuthFeature,
  (state) => state.code,
);

const selectCancelledRoute = createSelector(selectAuthFeature, (state) => state.cancelledRoute);

const selectStep = createSelector(selectAuthFeature, (state) => state.step);

const selectAvailable = createSelector(selectAuthFeature, (state) => state.available);

const selectIsLoading = createSelector(selectAuthFeature, (state) => state.loading);

const selectRegionList = createSelector(selectAuthFeature, (state) => state.regionList);

const selectRegion = createSelector(selectAuthFeature, (state) => state.region);

const selectError = createSelector(selectAuthFeature, (state) => state.error);

export const authSelectors = {
  selectAuthState: selectAuthFeature,
  selectEmail,
  selectHash,
  selectCode,
  selectCancelledRoute,
  selectAvailable,
  selectRegionList,
  selectRegion,
  selectError,
  selectStep,
  selectIsLoading,
};
