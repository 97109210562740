<div
  class="image"
  [ngClass]="styleClass()"
>
  @if (isLoading()) {
    <cheelee-loader
      [background]="loaderBackground()"
      class="content__loader"
      size="m"
    />
  }
  @let src = ngSrc();
  @if (src) {
    <img
      class="image__img"
      [ngClass]="imgStyleClass()"
      [alt]="alt()"
      [ngSrc]="src"
      (load)="isLoading.set(false)"
      [width]="!fill() ? width() : undefined"
      [height]="!fill() ? height() : undefined"
      [priority]="priority()"
      [fill]="fill()"
    />
  }
</div>
