import { computed, inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { routerSelectors } from '@store/router';
import { userSelectors } from '@store/user';
import { ViewSource } from '@api/backend';
import { authActions, AuthState } from '@store/auth';

@Injectable()
export class TabBarFacade {

  private readonly store = inject(Store);

  public currentUserId = this.store.selectSignal(userSelectors.selectUserId);

  public mediaId = this.store.selectSignal(routerSelectors.selectRouterParam('mediaId'));

  public offerId = this.store.selectSignal(routerSelectors.selectRouterParam('offerId'));

  public secondOrderMediaLink = computed(() => `media/${ this.mediaId() }`);

  public secondOrderMarketLink = computed(() => `market/offers/${ this.offerId() }`);

  public viewSource = computed(() => {
    const routeData = this.store.select(routerSelectors.selectData);
    if ('type' in routeData && !!routeData.type) {
      return routeData.type as ViewSource;
    }
    return null;
  });

  public userIdParam = this.store.selectSignal(routerSelectors.selectRouterParam('userId'));

  public updateAuthState(value: Partial<AuthState>): void {
    this.store.dispatch(authActions.updateState({ value }));
  }
}
