import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { RouterState } from './router-state.model';
import { Data } from '@angular/router';
import { ViewSource, ViewSourceType } from '@core/models';

const selectRouter = createFeatureSelector<RouterReducerState<RouterState>>('router');

const selectRouterState = createSelector(
  selectRouter,
  router => router?.state,
);

const selectParams = createSelector(
  selectRouter,
  router => router?.state?.params,
);

const selectRouterParam = (param: string) => createSelector(selectParams, (params) => params && params[param]);

const selectQueryParams = createSelector(
  selectRouter,
  router => router?.state?.queryParams,
);

const selectQueryRouterParam = (param: string) =>
  createSelector(selectQueryParams, (params) => params && params[param]);

const selectUrl = createSelector(
  selectRouter,
  router => router?.state?.url,
);

const selectFragment = createSelector(selectRouter, router => router?.state?.fragment);

const selectData = createSelector(selectRouter, router => router?.state?.data);

const selectFeedType = createSelector(selectData, (data: Data) =>
  data['type'] ? (data['type'] as ViewSourceType) : ViewSource.Recommendations,
);

const selectRouterNavigationId = createSelector(
  selectRouter,
  router => router.navigationId,
);


export const routerSelectors = {
  selectRouterState,
  selectParams,
  selectRouterParam,
  selectQueryParams,
  selectQueryRouterParam,
  selectUrl,
  selectFragment,
  selectData,
  selectFeedType,
  selectRouterNavigationId,
};
