/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OfferPrice {
    /**
     * Upper case name of the currency
     */
    currency?: OfferPrice.CurrencyEnum;
    /**
     * Initial price of the offer
     */
    initial?: string;
    /**
     * Price value to show to the user. Will be used during checkout-page
     */
    _final?: string;
    /**
     * Discount percent. 0-100
     */
    discount_percent?: string | null;
}
export namespace OfferPrice {
    export type CurrencyEnum = 'USD' | 'LEE';
    export const CurrencyEnum = {
        Usd: 'USD' as CurrencyEnum,
        Lee: 'LEE' as CurrencyEnum
    };
}


