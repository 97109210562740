/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetOfferPaymentMethods200ResponseDataItemsInnerObject } from './getOfferPaymentMethods200ResponseDataItemsInnerObject';


export interface GetOfferPaymentMethods200ResponseDataItemsInner { 
    /**
     * Payment method type
     */
    type?: GetOfferPaymentMethods200ResponseDataItemsInner.TypeEnum;
    object?: GetOfferPaymentMethods200ResponseDataItemsInnerObject;
}
export namespace GetOfferPaymentMethods200ResponseDataItemsInner {
    export type TypeEnum = 'apple' | 'google' | 'internal' | 'external';
    export const TypeEnum = {
        Apple: 'apple' as TypeEnum,
        Google: 'google' as TypeEnum,
        Internal: 'internal' as TypeEnum,
        External: 'external' as TypeEnum
    };
}


