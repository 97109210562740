/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OfferRarity { 
    /**
     * Unique identifier of the rarity
     */
    id?: string;
    /**
     * Capitalized name of the rarity
     */
    name?: string;
    /**
     * Color as a hex code
     */
    color?: string;
    /**
     * Tier for ordering
     */
    tier?: number;
}

