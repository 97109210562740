/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * User already has an active trade in
 */
export interface TradeInAlreadyHasError { 
    error_key?: TradeInAlreadyHasError.ErrorKeyEnum;
}
export namespace TradeInAlreadyHasError {
    export type ErrorKeyEnum = 'trade_in.already_in_progress';
    export const ErrorKeyEnum = {
        TradeInAlreadyInProgress: 'trade_in.already_in_progress' as ErrorKeyEnum
    };
}


