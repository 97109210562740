/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodPrice } from './paymentMethodPrice';
import { PaymentMethodExternal } from './paymentMethodExternal';
import { PaymentMethodInternal } from './paymentMethodInternal';
import { Image } from './image';
import { PaymentMethodApple } from './paymentMethodApple';
import { PaymentMethodGoogle } from './paymentMethodGoogle';


/**
 * @type GetOfferPaymentMethods200ResponseDataItemsInnerObject
 * @export
 */
export type GetOfferPaymentMethods200ResponseDataItemsInnerObject = PaymentMethodApple | PaymentMethodExternal | PaymentMethodGoogle | PaymentMethodInternal;

