import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  numberAttribute,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  signal,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';

import { debounceTime, noop, tap } from 'rxjs';

import { IconComponent } from '@shared/components';
import { AutofocusDirective, RippleDirective } from '@shared/directives';
import { SymbolCounterComponent } from '@shared/components/symbol-counter/symbol-counter.component';

type InputVariant = 'primary' | 'outlined';

type InputMode = 'text' | 'password';

type TextCase = 'lowercase' | 'uppercase' | 'unset';

@Component({
  selector: 'cheelee-input',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    IconComponent,
    CommonModule,
    RippleDirective,
    AutofocusDirective,
    SymbolCounterComponent,
  ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input()
  public variant: InputVariant = 'primary';

  @Input()
  public placeholder = '';

  @Input({ transform: numberAttribute })
  public maxlength: number | null = null;

  @Input({ transform: booleanAttribute })
  public withAutofocus?: boolean;

  @Input()
  public textCase: TextCase = 'unset';

  @Input({ transform: numberAttribute })
  public debounceTime = 0;

  @Input()
  public name?: string;

  @ViewChild('contentWrapper')
  private readonly contentWrapper!: ElementRef<HTMLDivElement>;

  @Input()
  public set type(value: InputMode) {
    if (value === 'password') {
      this.isPasswordMode.set(true);
    }

    this.mode = value;
  }

  @Output()
  public enterPress = new EventEmitter<void>();

  public control = new FormControl<string>('');

  public hasContent = signal(false);

  public canShowPassword = signal(false);

  public isPasswordMode = signal(false);

  public mode: InputMode = 'text';

  public onChange: (value: string | null) => void = noop;

  public onTouch: () => void = noop;

  public invalid = signal(false);

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  public get type(): InputMode {
    return this.mode;
  }

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly renderer: Renderer2,
    private readonly hostRef: ElementRef,
    private readonly cdRef: ChangeDetectorRef,
    @Self() @Optional() private readonly ngControl: NgControl,
  ) {
    this.ngControl.valueAccessor = this;
  }

  public ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        tap((value) => {
          this.onChange(value);

          if (this.ngControl?.touched || this.ngControl?.dirty) {
            this.invalid.set(!!this.ngControl?.invalid);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  public ngAfterViewInit(): void {
    if (this.contentWrapper.nativeElement.childNodes.length > 0) {
      this.hasContent.set(true);
    } else {
      this.renderer.removeChild(this.contentWrapper.nativeElement.parentNode, this.contentWrapper.nativeElement);
    }

    this.cdRef.detectChanges();
  }

  public registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }

    this.setHostElementDisabled();
  }

  public writeValue(value: string): void {
    this.control.patchValue(value);
  }

  public resetValue(): void {
    if (this.control.value?.length) {
      this.control.reset('');
    }
  }

  public toggleShowText(): void {
    this.canShowPassword.set(!this.canShowPassword());
    this.type = this.canShowPassword() ? 'text' : 'password';
  }

  private setHostElementDisabled(): void {
    if (this.hostRef) {
      this.renderer[this.control.disabled ? 'setAttribute' : 'removeAttribute'](
        this.hostRef.nativeElement,
        'disabled',
        '',
      );

      this.cdRef.markForCheck();
    }
  }
}
