/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PromocodeActionObject } from './promocodeActionObject';


export interface PromocodeAction { 
    type?: PromocodeAction.TypeEnum;
    object?: PromocodeActionObject;
}
export namespace PromocodeAction {
    export type TypeEnum = 'discount_percent' | 'discount_abs';
    export const TypeEnum = {
        Percent: 'discount_percent' as TypeEnum,
        Abs: 'discount_abs' as TypeEnum
    };
}


