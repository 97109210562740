import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Snippet } from '@api/backend';
import { userActions, userSelectors } from '@store/user';
import { routerActions } from '@store/router';


@Injectable({ providedIn: 'root' })
export class UserFacade {

  public readonly browsingUser = this.store.selectSignal(userSelectors.selectBrowsingUserData);

  public readonly browsingUser$ = this.store.select(userSelectors.selectBrowsingUserData);

  public readonly isLoading = this.store.selectSignal(userSelectors.selectIsLoading);

  public readonly userMedia = this.store.selectSignal(userSelectors.selectUserMedia);

  public readonly feedIsLoading = this.store.selectSignal(userSelectors.selectUserMediaIsLoading);

  public readonly nextPageToken = this.store.selectSignal(userSelectors.selectUserMediaNextPageToken);


  constructor(
    private store: Store,
  ) {}

  public getSnippet(): Observable<Snippet | undefined> {
    this.store.dispatch(userActions.getSnippet());
    return this.store.select(userSelectors.selectSnippet);
  }

  public getBrowsingUser(): void {
    this.store.dispatch(userActions.getBrowsingUser());
  }

  public loadUserMedia(isNextPart?: boolean): void {
    this.store.dispatch(userActions.loadFeedMedia({ isNextPart }));
  }

  public openMedia(media_id: string): void {
    // this.overlayService.openGetAppDialog();
    this.store.dispatch(routerActions.navigate({ path: `media/${ media_id }` }));
  }
}
