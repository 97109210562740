import { CdkScrollable } from '@angular/cdk/overlay';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { I18NextModule } from 'angular-i18next';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@core/core.module';
import { AvatarComponent, ButtonComponent } from '@shared/components';
import { TabBarComponent } from '@shared/components/tab-bar/tab-bar.component';
import { OpenGetAppDirective } from '@shared/directives';
import { storeBaseProviders } from '@store';
import { AppInitService } from '@app/app-init.sevice';
import { environment } from '@environments/environment';

@NgModule({
  declarations: [ AppComponent ],
  bootstrap: [ AppComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    CdkScrollable,
    ReactiveFormsModule,
    I18NextModule,
    ButtonComponent,
    OpenGetAppDirective,
    AvatarComponent,
    TabBarComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    { provide: Sentry.TraceService, deps: [ Router ] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [ Sentry.TraceService ],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (initService: AppInitService) => () => initService.init(environment),
      deps: [ AppInitService ],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    storeBaseProviders,
  ],
})
export class AppModule {
}
