/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Inappropriate conditions, for example, there are no parameters for a certain rarity of glasses
 */
export interface TradeInInappropriateConditionsError { 
    error_key?: TradeInInappropriateConditionsError.ErrorKeyEnum;
}
export namespace TradeInInappropriateConditionsError {
    export type ErrorKeyEnum = 'trade_in.inappropriate_conditions';
    export const ErrorKeyEnum = {
        TradeInInappropriateConditions: 'trade_in.inappropriate_conditions' as ErrorKeyEnum
    };
}


