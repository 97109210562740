import { Location } from '@angular/common';
import { Directive, HostListener, input } from '@angular/core';

import { RouterFacade } from '@core/facades';

@Directive({
  selector: '[cheeleeGoBack]',
  standalone: true,
})
export class GoBackDirective {

  backPath = input<string>();

  constructor(
    private readonly location: Location,
    private readonly routerFacade: RouterFacade,
  ) {}

  @HostListener('click')
  private onClick(): void {
    const { navigationId } = this.location.getState() as { navigationId: number };

    const backPath = this.backPath();

    if (navigationId > 1) {
      this.location.back();
    } else {
      this.routerFacade.navigate({ path: backPath ?? '/' });
    }
  }
}
