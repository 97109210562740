<div class="donation">
  <div class="donation__container">
    @for (item of donateItems; track item.amount; let i = $index) {
      <label
        class="donation__item"
        cheeleeOpenGetApp [picture]="'lee'"
        [preventDefault]="false"
        [class.active]="control.value === i"
        [eventName]="analyticsEvents.DonateValueTap"
      >
        <input
          type="radio"
          class="hidden"
          [value]="i"
          [formControl]="control"
        />
        {{ (control.value === i ? 'Donate ' : '') + item.amount + ' ' + item.currency + ' ' + item.icon }}
      </label>
    }
  </div>
</div>
