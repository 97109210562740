import { Signal } from '@angular/core';
import { SelectDialogService } from '@modules/market/components/select-dialog/select-dialog.service';

export interface SelectOption {
  value: string;
  label: string;
  type?: string;
  labelPrefix?: string;
  disabled?: boolean;
  icon?: string;
}

export interface SelectDialogData {
  options: Signal<SelectOption[]>,
  selected: string | null,
  service: SelectDialogService,
  apply: boolean,
}
