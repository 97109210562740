interface Errors {
  errors: Array<Error>;
}

interface Error {
  error_key: string;
  error_message: string;
  error_loc: Array<string>;
}

export interface ErrorResponse {
  error: Errors;
  message: string;
  status: number;
  url: string;
}

export const getErrorKey = (error?: ErrorResponse | null) => error?.error.errors[0].error_key ?? null;
