/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Image } from './image';


export interface PaymentMethodApple { 
    /**
     * Unique identifier of the payment method
     */
    id?: string;
    /**
     * Unique identifier of the product in Apple Store
     */
    product_id?: string;
    image?: Image;
}

