export enum AuthPhoneTypes {
  SMS = 'sms',
  FLASH_CALL = 'flash_call',
}

export enum AuthOAuthTypes {
  VK = 'vk',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export enum AuthTypes {
  PHONE = 'phone',
  EMAIL = 'email',
  OAUTH = 'oauth',
}

export enum AuthSteps {
  PENDING = 0,
  START = 1,
  CONTACT = 2,
  CODE = 3,
  PASSWORD = 4,
  DONE = 5,
}

export const AUTH_CODE_LENGTH = {
  [AuthPhoneTypes.SMS]: 6,
  [AuthPhoneTypes.FLASH_CALL]: 4,
};

export enum GetSessionActionTypes {
  NEW = 'new',
  REFRESH = 'refresh',
}
