<div
  #swiperContainerRef
  class="swiper"
  (animationend)="swiperContainerRef.style.animation = 'unset'"
>
  @if (isMobile()) {

    @if (mode() === MediaActionsMode.RECOMMENDATIONS) {
      <cheelee-feed-nav
        *ngIf="isMediaType(currentItem) || isUserVideoType(currentItem) || isAdsType(currentItem)"
        [isMuted]="isMuted()"
        (toggleMuteClicked)="toggleMute()"
      />
    } @else {
      <cheelee-header
        [isFixed]="true"
        [background]="null"
      >
        <div class="header__container">
          <div class="header__go-back">
            <button
              class="header__go-back-btn"
              cheeleeGoBack
              cheeleeRipple
              isCenteredRipple
            >
              <cheelee-icon
                class="header__go-back-icon"
                iconId="arrow-left-icon"
              />
            </button>
          </div>
          <cheelee-top-button
            class="feed-nav__right-bottom"
            [icon]="!isMuted() ? 'sprite.svg#icon-not-muted' : 'sprite.svg#icon-muted'"
            (clicked)="toggleMute()"
            [eventName]="analyticsEvents.VolumeTap"
          />
        </div>
      </cheelee-header>
    }
  }
  <swiper-container #swiper class="swiper__container">
    @for (_ of [].constructor(feedLength()); track index; let index = $index) {
      <swiper-slide>
        @let feedItem = getByIndex(index)();
        @if (index <= currentIndex() + 2 && index >= currentIndex() - 2 && feedItem) {
          <section
            class="swiper__actions-overlay"
            [style.opacity]="withTransparentActions() ? '.4' : '1'"
          >
            <div class="flex flex-column flex-grow-1 flex-shrink-1">
              <cheelee-about-media
                [item]="feedItem"
                [isActive]="currentIndex() === index"
              />

              @if (isMediaType(feedItem) || isUserVideoType(feedItem)) {
                <cheelee-donation/>
              }

            </div>
            <cheelee-media-actions
              [item]="feedItem"
              [mode]="mode()"
              (closeComments)="openGetAppDialog(analyticsEvents.MediaCommentTap,'share')"
              (like)="canShowLikeEffect.set(true)"
              (openComments)="openGetAppDialog(analyticsEvents.MediaCommentTap,'share')"
            />
          </section>

          <div class="swiper__video-wrapper">
            @if (canShowVideoSpinner() && isActiveVideoPosterLoaded()) {
              <cheelee-spinner class="swiper__spinner" size="32"/>
            }
            @if (isMediaType(feedItem)) {
              @let media = feedItem.object;
              <ng-container *ngTemplateOutlet="videoTpl; context: {
                url: media.media_url,
                preview: media.preview_url,
                index: index,
              }"></ng-container>
            }

            @if (isAdsType(feedItem)) {
              @let ad = getAdObject(feedItem);
              @if (ad) {
                <ng-container *ngTemplateOutlet="videoTpl; context: {
                url: ad.video?.bitrates?.[0]?.url,
                preview: ad.video?.frame1?.img?.[0]?.url,
                index: index,
              }"></ng-container>
              }
            }

            @if (isUserVideoType(feedItem)) {
              @let userVideo = feedItem.object;
              <ng-container *ngTemplateOutlet="videoTpl; context: {
                url: userVideo.video?.bitrates?.[0]?.url,
                preview: userVideo.video?.frame1?.img?.[0]?.url,
                index: index,
              }"></ng-container>
            }
          </div>

        }
      </swiper-slide>
    }
  </swiper-container>

  <div class="swiper__effects">
    @if (canShowPauseEffect()) {
      <div
        class="swiper__pause-circle fade-in-out"
        (animationend)="canShowPauseEffect.set(false)"
      >
        @if (activeVideo()?.paused) {
          <cheelee-icon
            iconId="pause-video-icon"
            size="12"
          />
        } @else {
          <cheelee-icon
            iconId="play-video-icon"
            size="12"
          />
        }
      </div>
    }

    @if (canShowLikeEffect()) {
      <div
        class="swiper__like-effect"
        (animationend)="canShowLikeEffect.set(false)"
      ></div>
    }
  </div>

</div>

<ng-template #videoTpl let-url="url" let-preview="preview" let-index="index">
  <video
    #htmlVideoElement
    class="swiper__video"
    [muted]="isMuted()"
    [poster]="preview"
    [src]="url"
    cheeleeSingleDoubleClick
    loop
    playsinline
    preload="auto"
    (canplay)="canShowVideoSpinner.set(false)"
    (loadeddata)="canShowVideoSpinner.set(false)"
    (singleClick)="onVideoClick()"
    (waiting)="canShowVideoSpinner.set(true)"
    [analytics]="htmlVideoElement.paused ? analyticsEvents.MediaPlayTap : analyticsEvents.MediaPauseTap"
  ></video>
</ng-template>
