import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';

@Pipe({
  name: 'countdown',
  pure: false,
  standalone: true,
})
export class CountdownPipe implements PipeTransform, OnDestroy {
  private timer: any;

  constructor(private changeDetector: ChangeDetectorRef, private ngZone: NgZone) {}

  transform(value: string): string {
    if (!value) return '00:00:00';

    const targetTime = new Date(value).getTime();

    if (isNaN(targetTime)) return '00:00:00';

    this.clearTimer();

    this.ngZone.runOutsideAngular(() => {
      this.timer = setInterval(() => {
        this.changeDetector.markForCheck();
      }, 1000);
    });

    return this.getRemainingTime(targetTime);
  }

  private getRemainingTime(targetTime: number): string {
    const now = Date.now();
    const diff = Math.max(0, targetTime - now);

    const hours = Math.floor(diff / (1000 * 60 * 60)).toString().padStart(2, '0');
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    const seconds = Math.floor((diff % (1000 * 60)) / 1000).toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }

  private clearTimer(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  ngOnDestroy(): void {
    this.clearTimer();
  }
}
