/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderStatusSuccessAssetObject } from './orderStatusSuccessAssetObject';


export interface OrderStatusSuccessAsset { 
    /**
     * Type of the purchased item
     */
    type?: OrderStatusSuccessAsset.TypeEnum;
    object?: OrderStatusSuccessAssetObject;
}
export namespace OrderStatusSuccessAsset {
    export type TypeEnum = 'glasses' | 'lootbox' | 'common' | 'external_promocode';
    export const TypeEnum = {
        Glasses: 'glasses' as TypeEnum,
        Lootbox: 'lootbox' as TypeEnum,
        Common: 'common' as TypeEnum,
        ExternalPromocode: 'external_promocode' as TypeEnum
    };
}


