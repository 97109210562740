/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LootboxChanceContent } from './lootboxChanceContent';


export interface LootboxChance { 
    /**
     * The probability of a specific loot type dropping. Takes a value from 0 to 1
     */
    chance?: string;
    content?: LootboxChanceContent;
}

