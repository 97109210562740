/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TradeInInappropriateConditionsError } from './tradeInInappropriateConditionsError';
import { TradeInInvalidDiscountError } from './tradeInInvalidDiscountError';
import { TradeInAlreadyHasError } from './tradeInAlreadyHasError';


/**
 * @type TradeIn409Response
 * @export
 */
export type TradeIn409Response = TradeInAlreadyHasError | TradeInInappropriateConditionsError | TradeInInvalidDiscountError;

