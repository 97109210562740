import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, Observable, throwError } from 'rxjs';

import { Interceptor } from '@core/interceptors/interceptor';
import { GetSessionActionTypes } from '@core/models';
import { FingerprintService, SnackbarService } from '@core/services';
import { CookieService } from '@core/services/cookies/cookie.service';
import { TOKEN_NAME } from '@data/config';
import { HttpKeys, SPECIAL_CODES } from '@data/const';

@Injectable()
export class ErrorInterceptor extends Interceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private snackbarService: SnackbarService,
    protected override store: Store,
    protected override actions: Actions,
    protected override injector: Injector,
    protected override fingerprintService: FingerprintService,
    @Inject(LOCALE_ID) protected override locale: string,
  ) {
    super(store, actions, injector, fingerprintService, locale);
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          const errorKey = error.error?.errors?.[0]?.error_key;

          if (!errorKey) {
            return this.throwDisplayedError(error);
          }

          switch (error.status) {
            case HttpStatusCode.Unauthorized:
              switch (errorKey) {
                case HttpKeys.HttpAuthorizationExpiredKey:
                  return this.getSession(GetSessionActionTypes.REFRESH, request, next);

                default:
                  return this.getSession(GetSessionActionTypes.NEW, request, next);
              }

            case HttpStatusCode.NotFound:
              switch (errorKey) {
                case HttpKeys.HttpAuthorizationSessionNotFound:
                  return this.getSession(GetSessionActionTypes.NEW, request, next);

                case HttpKeys.PromocodeNotFound: {
                  // eslint-disable-next-line no-console
                  console.warn('Promocode not found. Suppressing error.');
                  return throwError(() => error);
                }

                default:
                  return this.throwDisplayedError(error);
              }

            case HttpStatusCode.Forbidden:
              switch (errorKey) {
                case HttpKeys.Forbidden: {
                  this.cookieService.clear(TOKEN_NAME);
                  location?.reload();
                  return throwError(() => error);
                }

                case HttpKeys.HttpAuthorizationRequiredKey:
                  return throwError(() => error);

                default:
                  return this.throwDisplayedError(error);
              }
            // Добавлена обработка ошибки 409
            case HttpStatusCode.Conflict:

              switch (errorKey) {
                case HttpKeys.OrderLimitsReached:
                  this.snackbarService.show('common:too_many_requests', 'error');
                  return throwError(() => error);

                default:
                  return this.throwDisplayedError(error);
              }

            case this.specialErrorStatus(error):
              return this.throwDisplayedError(error);

            default:
              return throwError(() => error);
          }
        }

        return throwError(() => error);
      }),
    );
  }

  private specialErrorStatus(error: HttpErrorResponse): number {
    return SPECIAL_CODES.find((code) => code === error?.status) || 0;
  }

  private throwDisplayedError(error: unknown): Observable<never> {
    return throwError(() => error);
  }
}
