/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurchaseHistoryOrder } from './purchaseHistoryOrder';


export interface GetOrders200Response { 
    page_token?: string;
    prev_page_token?: string | null;
    next_page_token?: string | null;
    items?: Array<PurchaseHistoryOrder>;
}

