<cheelee-dialog-wrapper>
  <div
    class="auth__header"
    ngProjectAs="header"
  >
    <button
      class="auth__back-btn btn circle"
      cheeleeRipple
      isCenteredRipple
      withOverflowRipple
      (click)="goBack()"
    >
      <cheelee-icon iconId="arrow-left-icon" />
    </button>
    <button
      class="auth__support-btn btn circle"
      cheeleeRipple
      isCenteredRipple
      withOverflowRipple
      (click)="openSupportCurtain()"
    >
      <cheelee-icon iconId="paper-plane-icon" />
    </button>
  </div>

  <ng-container [ngSwitch]="authState()?.step">
    <ng-container *ngSwitchCase="AuthStep.START">
      <ng-container *ngTemplateOutlet="startStep" />
    </ng-container>

    <ng-container *ngSwitchCase="AuthStep.CONTACT">
      <ng-container *ngTemplateOutlet="contactStep" />
    </ng-container>

    <ng-container *ngSwitchCase="AuthStep.CODE">
      <ng-container *ngTemplateOutlet="codeStep" />
    </ng-container>

    <ng-container *ngSwitchCase="AuthStep.PASSWORD">
      <ng-container *ngTemplateOutlet="passwordStep" />
    </ng-container>
  </ng-container>
</cheelee-dialog-wrapper>

<ng-template #startStep>
  <form
    class="auth__form"
    [formGroup]="formGroup"
  >
    <div class="auth__start">
      <img
        alt="auth cheelee logo"
        height="252"
        ngSrc="./assets/img/cheelee-logo.png"
        width="252"
      />

      <span
        class="auth__login-text text-1"
        [innerHTML]="'auth:log_in_to_continue' | i18nextEager"
      ></span>

      <ng-container *ngIf="!isLoading(); else loadingSocials">
        <section
          class="auth__socials"
          *ngIf="available()!.length > 0"
          [class.active]="available()!.length > 0"
        >
          <button
            class="auth__social-btn"
            *ngFor="let social of available()"
            (click)="authWithSocial(social)"
          >
            <cheelee-icon
              [path]="'social-sprite.svg#' + social + '-icon'"
              size="32"
            />
          </button>
        </section>
      </ng-container>
      <ng-template #loadingSocials>
        <section class="auth__socials">
          <cheelee-loader size="m" />
        </section>
      </ng-template>

      <cheelee-button
        withFullWidth
        (clicked)="onSetStep(AuthStep.CONTACT, authType.EMAIL)"
      >
        {{ 'auth:type_email' | i18nextEager }}
      </cheelee-button>
    </div>

    <div
      class="auth__footer"
      [innerHTML]="'auth:tou' | i18nextEager"
    ></div>
  </form>
</ng-template>

<ng-template #contactStep>
  <form
    class="auth__form"
    [formGroup]="formGroup"
  >
    <div class="auth__contact">
      <p class="auth__email-header text-2 uppercase">
        {{ 'auth:email_log_in_1' | i18nextEager }}
      </p>
      <p class="auth__email-sub-header text-1">{{ 'auth:email_log_in_2' | i18nextEager }}</p>

      <div class="auth__input-wrapper">
        <cheelee-input
          class="auth__contact-input"
          [placeholder]="'auth:email_placeholder' | i18nextEager"
          formControlName="email"
          withAutofocus
        />
        <p
          class="auth__error-text text-error text-3 text-left"
          *ngIf="authState()?.error"
        >
          <ng-template
            [ngIf]="$any(authState()).error?.error?.errors?.length"
            [ngIfElse]="defaultErrorText"
          >
            {{ 'error:' + $any(authState()).error.error.errors[0].error_key! | i18nextEager }}
          </ng-template>
        </p>
      </div>

      <cheelee-button
        class="auth__get-email-btn"
        [isDisabled]="isLoading() || formGroup.controls.email.invalid || !!authState()?.error"
        withFullWidth
        (clicked)="onSubmit()"
      >
        <ng-container *ngIf="!isLoading(); else btnText">
          {{ 'auth:continue' | i18nextEager }}
        </ng-container>
        <ng-template #btnText>
          <cheelee-spinner />
        </ng-template>
      </cheelee-button>
    </div>
  </form>
</ng-template>

<ng-template #codeStep>
  <form
    class="auth__form"
    [formGroup]="formGroup"
  >
    <div class="auth__contact">
      <p class="auth__email-header text-2 uppercase">
        {{ 'auth:enter_verify_code' | i18nextEager }}
      </p>
      <p class="auth__email-sub-header text-1">
        {{ 'auth:code_sent_to' | i18nextEager }} {{ authState()?.email }}
      </p>

      <cheelee-code-input
        class="auth__code-input"
        [hasErrors]="!!stateError()"
        [isDisabled]="isLoading()"
        (codeCompleted)="onSubmit($event)"
      />

      <p
        class="auth__error-text text-error text-3 text-left"
        *ngIf="authState()?.error"
      >
        <span
          *ngIf="$any(authState()).error?.error?.errors?.length; else defaultErrorText"
          [innerHTML]="'error:' + $any(authState()).error.error.errors[0].error_key! | i18nextEager"
        ></span>
<!--        <ng-template-->
<!--          [ngIf]="$any(authState()).error?.error?.errors?.length"-->
<!--          [ngIfElse]="defaultErrorText"-->
<!--        >-->
<!--          {{ 'error:' + $any(authState()).error.error.errors[0].error_key! | i18nextEager }}-->
<!--        </ng-template>-->
      </p>

      <cheelee-button
        class="auth__resend-code-btn"
        [isDisabled]="isLoading() || countdownState?.action !== 'done'"
        withFullWidth
        (clicked)="resendEmailCode()"
      >
        <ng-container *ngIf="!isLoading(); else btnText">
          {{ 'auth:code_get_new' | i18nextEager }}
        </ng-container>
        <ng-template #btnText>
          <cheelee-spinner />
        </ng-template>
      </cheelee-button>

      <p class="auth__counter-text text-1">
        <ng-template [ngIf]="countdownState?.action !== 'done'">{{
          'auth:code_repeat_after' | i18nextEager
        }}</ng-template>

        <span [hidden]="countdownState?.action === 'done'">
          <countdown
            #cd
            [config]="countdownConfig"
            style="display: inline-block"
            (event)="countdownEvent($event)"
          />
          {{ 'common:second' | i18nextEager }}
        </span>
      </p>
    </div>
  </form>
</ng-template>

<ng-template #passwordStep>
  <form
    class="auth__form"
    [formGroup]="formGroup"
  >
    <div class="auth__contact">
      <p class="auth__email-header text-2 uppercase">
        {{ 'auth:2_fa' | i18nextEager }}
      </p>
      <p class="auth__email-sub-header text-1">
        {{ 'auth:enter_password_moto' | i18nextEager }}
      </p>

      <cheelee-input
        class="auth__contact-input"
        name="2fa-password"
        type="password"
        [placeholder]="'auth:password_placeholder' | i18nextEager"
        formControlName="password"
        withAutofocus
      />

      <p
        class="auth__error-text text-error text-3 text-left"
        *ngIf="authState()?.error"
      >
        <ng-template
          [ngIf]="$any(authState()).error?.error?.errors?.length"
          [ngIfElse]="defaultErrorText"
        >
          {{ 'error:' + $any(authState()).error.error.errors[0].error_key! | i18nextEager }}
        </ng-template>
      </p>

      <cheelee-button
        class="auth__forgot-pass-btn"
        isSmall
        variant="interactive"
        (clicked)="openSupportCurtain()"
      >
        {{ 'auth:forgot_password_btn' | i18nextEager }}
      </cheelee-button>

      <cheelee-button
        class="auth__resend-code-btn"
        [isDisabled]="isLoading() || !formGroup.controls.password.value"
        withFullWidth
        (clicked)="onSubmit()"
      >
        <ng-container *ngIf="!isLoading(); else btnText">
          {{ 'auth:continue' | i18nextEager }}
        </ng-container>
        <ng-template #btnText>
          <cheelee-spinner />
        </ng-template>
      </cheelee-button>
    </div>
  </form>
</ng-template>

<ng-template #defaultErrorText>{{ 'error:http_0' | i18nextEager }}</ng-template>
