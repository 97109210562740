/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CompleteApplePurchase400Response { 
    error_key?: CompleteApplePurchase400Response.ErrorKeyEnum;
}
export namespace CompleteApplePurchase400Response {
    export type ErrorKeyEnum = 'receipt.invalid' | 'receipt.already_verified';
    export const ErrorKeyEnum = {
        Invalid: 'receipt.invalid' as ErrorKeyEnum,
        AlreadyVerified: 'receipt.already_verified' as ErrorKeyEnum
    };
}


