/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Rarity } from './rarity';
import { Amount } from './amount';


export interface ProductGlasses { 
    rarity?: Rarity;
    max_profit?: Amount;
    /**
     * Remaining watch in seconds
     */
    remaining_watch_duration?: number;
}

