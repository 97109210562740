/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CompleteApplePurchase404Response { 
    error_key?: CompleteApplePurchase404Response.ErrorKeyEnum;
}
export namespace CompleteApplePurchase404Response {
    export type ErrorKeyEnum = 'user.not_found' | 'offer.not_found' | 'order.not_found';
    export const ErrorKeyEnum = {
        UserNotFound: 'user.not_found' as ErrorKeyEnum,
        OfferNotFound: 'offer.not_found' as ErrorKeyEnum,
        OrderNotFound: 'order.not_found' as ErrorKeyEnum
    };
}


