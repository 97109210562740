import { booleanAttribute, ChangeDetectionStrategy, Component, effect, inject, input, signal } from '@angular/core';
import { LoaderComponent } from '@shared/components';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { LogService } from '@core/services';

@Component({
  selector: 'cheelee-image',
  standalone: true,
  imports: [
    LoaderComponent,
    NgOptimizedImage,
    NgClass,
  ],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {

  private readonly log = inject(LogService);

  public ngSrc = input<string | null>();

  public styleClass = input<string>();

  public imgStyleClass = input<string>();

  public alt = input<string | null>('');

  public priority = input(false, { transform: booleanAttribute });

  public fill = input(false, { transform: booleanAttribute });

  public width = input<number>();

  public height = input<number>();

  public loaderSize = input<'s' | 'm' | 'l'>();

  public loaderBackground = input<string>('rgba(255,255,255,0.05)');

  public isLoading = signal(true);

  private inputValidator = effect(() => {
    if (this.fill() && this.width() && this.height()) {
      this.log.error('Fill and width and height are mutually exclusive for NgOptimizedImage', 'ImageComponent');
    }
  });

}
