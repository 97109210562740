/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductInfoObject } from './productInfoObject';


export interface ProductInfo { 
    type?: ProductInfo.TypeEnum;
    object?: ProductInfoObject;
}
export namespace ProductInfo {
    export type TypeEnum = 'common' | 'glasses' | 'lootbox';
    export const TypeEnum = {
        Common: 'common' as TypeEnum,
        Glasses: 'glasses' as TypeEnum,
        Lootbox: 'lootbox' as TypeEnum
    };
}


