/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PurchaseExternalNoAuth409Response { 
    error_key?: PurchaseExternalNoAuth409Response.ErrorKeyEnum;
}
export namespace PurchaseExternalNoAuth409Response {
    export type ErrorKeyEnum = 'wallet.orders_limit_reached';
    export const ErrorKeyEnum = {
        WalletOrdersLimitReached: 'wallet.orders_limit_reached' as ErrorKeyEnum
    };
}


