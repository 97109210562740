import { Environment } from '@core/models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {

  init(environment: Environment): void {
    if (environment.production) {
      this.loadGTM();
    }
    this.addPreconnectLink(environment.cdn_url);
  }

  private loadGTM(): void {
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        const f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-K33WL2JD');
    `;
    document.head.appendChild(gtmScript);
  }

  private addPreconnectLink(cdnUrl: string): void {
    if (!cdnUrl) {
      // eslint-disable-next-line no-console
      console.warn('A valid cdn_url must be specified');
      return;
    }

    const existingLink = document.head.querySelector(`link[rel="preconnect"][href="${cdnUrl}"]`);
    if (existingLink) {
      // eslint-disable-next-line no-console
      console.log(`A link with href "${ cdnUrl }" already exists in the document.`);
      return;
    }

    const linkElement = document.createElement('link');
    linkElement.rel = 'preconnect';
    linkElement.href = cdnUrl;

    document.head.appendChild(linkElement);
  }
}
