import { ValidationErrors } from 'ngrx-forms';

export function socialLinksValidator<T extends string>(value: T): ValidationErrors {
  if (!value) {
    return {};
  }

  const type = ['instagram', 'tiktok', 'youtube'].find((name) => value.includes(name));
  let socialRecord;

  switch (type) {
    case 'instagram':
      socialRecord = {
        regex: /^(https:\/\/(www.)?instagram.com)\/\S+$/,
        mask: 'https://www.instagram.com/{username}',
      };
      break;

    case 'tiktok':
      socialRecord = {
        regex: /^(https:\/\/(www.)?tiktok.com)\/\@\S+$/,
        mask: 'https://www.tiktok.com/@{username}',
      };
      break;

    case 'youtube':
      socialRecord = {
        regex: /^(https:\/\/(www.)?youtube.com)\/(c|channel)\/\S+/,
        mask: 'https://www.youtube.com/c/{channel}',
      };
      break;

    default:
      return { socialError: true, mask: '' } as ValidationErrors;
  }

  if (socialRecord) {
    if (!new RegExp(socialRecord.regex, 'gm').test(value)) {
      return { socialError: true, mask: socialRecord.mask } as ValidationErrors;
    }
  }

  return {};
}
