export const REGION_LIST: RegionData[] = [
  {
    iso: 'AL',
    code: 355,
    code_string: '+355',
    name: { en: 'Albania', ru: 'Албания' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'DZ',
    code: 213,
    code_string: '+213',
    name: { en: 'Algeria', ru: 'Алжир' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'AO',
    code: 244,
    code_string: '+244',
    name: { en: 'Angola', ru: 'Ангола' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'AG',
    code: 1268,
    code_string: '+1268',
    name: { en: 'Antigua & Barbuda', ru: 'Антигуа и Барбуда' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'AR',
    code: 54,
    code_string: '+54',
    name: { en: 'Argentina', ru: 'Аргентина' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'AM',
    code: 374,
    code_string: '+374',
    name: { en: 'Armenia', ru: 'Армения' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'AW',
    code: 297,
    code_string: '+297',
    name: { en: 'Aruba', ru: 'Аруба' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'AU',
    code: 61,
    code_string: '+61',
    name: { en: 'Australia', ru: 'Австралия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'AT',
    code: 43,
    code_string: '+43',
    name: { en: 'Austria', ru: 'Австрия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'AZ',
    code: 994,
    code_string: '+994',
    name: { en: 'Azerbaijan', ru: 'Азербайджан' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BS',
    code: 1242,
    code_string: '+1242',
    name: { en: 'Bahamas', ru: 'Багамские Острова' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BH',
    code: 973,
    code_string: '+973',
    name: { en: 'Bahrain', ru: 'Бахрейн' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BD',
    code: 880,
    code_string: '+880',
    name: { en: 'Bangladesh', ru: 'Бангладеш' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BY',
    code: 375,
    code_string: '+375',
    name: { en: 'Belarus', ru: 'Беларусь' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BE',
    code: 32,
    code_string: '+32',
    name: { en: 'Belgium', ru: 'Бельгия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BZ',
    code: 501,
    code_string: '+501',
    name: { en: 'Belize', ru: 'Белиз' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BJ',
    code: 229,
    code_string: '+229',
    name: { en: 'Benin', ru: 'Бенин' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BM',
    code: 1441,
    code_string: '+1441',
    name: { en: 'Bermuda', ru: 'Бермудские острова' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BO',
    code: 591,
    code_string: '+591',
    name: { en: 'Bolivia', ru: 'Боливия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BA',
    code: 387,
    code_string: '+387',
    name: { en: 'Bosnia & Herzegovina', ru: 'Босния и Герцеговина' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BW',
    code: 267,
    code_string: '+267',
    name: { en: 'Botswana', ru: 'Ботсвана' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BR',
    code: 55,
    code_string: '+55',
    name: { en: 'Brazil', ru: 'Бразилия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'VG',
    code: 1284,
    code_string: '+1284',
    name: { en: 'British Virgin Islands', ru: 'Британские Виргинские острова' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BG',
    code: 359,
    code_string: '+359',
    name: { en: 'Bulgaria', ru: 'Болгария' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'BF',
    code: 226,
    code_string: '+226',
    name: { en: 'Burkina Faso', ru: 'Буркина Фасо' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'KH',
    code: 855,
    code_string: '+855',
    name: { en: 'Cambodia', ru: 'Камбоджа' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'CM',
    code: 237,
    code_string: '+237',
    name: { en: 'Cameroon', ru: 'Камерун' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'CV',
    code: 238,
    code_string: '+238',
    name: { en: 'Cape Verde', ru: 'Кабо-Верде' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'KY',
    code: 1345,
    code_string: '+1345',
    name: { en: 'Cayman Islands', ru: 'Каймановы Острова' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'TD',
    code: 235,
    code_string: '+235',
    name: { en: 'Chad', ru: 'Чад' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'CL',
    code: 56,
    code_string: '+56',
    name: { en: 'Chile', ru: 'Чили' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'CO',
    code: 57,
    code_string: '+57',
    name: { en: 'Colombia', ru: 'Колумбия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'KM',
    code: 269,
    code_string: '+269',
    name: { en: 'Comoros', ru: 'Коморы' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'CG',
    code: 243,
    code_string: '+243',
    name: { en: 'Congo - Brazzaville', ru: 'Республика Конго' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'CR',
    code: 506,
    code_string: '+506',
    name: { en: 'Costa Rica', ru: 'Коста-Рика' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'HR',
    code: 385,
    code_string: '+385',
    name: { en: 'Croatia', ru: 'Хорватия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'CW',
    code: 599,
    code_string: '+599',
    name: { en: 'Curaçao', ru: 'Кюрасао' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'CY',
    code: 357,
    code_string: '+357',
    name: { en: 'Cyprus', ru: 'Кипр' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'CZ',
    code: 420,
    code_string: '+420',
    name: { en: 'Сzech', ru: 'Чехия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'CI',
    code: 225,
    code_string: '+225',
    name: { en: 'Côte d’Ivoire', ru: 'Кот-д’Ивуар' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'DK',
    code: 45,
    code_string: '+45',
    name: { en: 'Denmark', ru: 'Дания' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'DJ',
    code: 253,
    code_string: '+253',
    name: { en: 'Djibouti', ru: 'Джибути' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'DM',
    code: 1767,
    code_string: '+1767',
    name: { en: 'Dominica', ru: 'Доминика' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'D0',
    code: 1,
    code_string: '+1',
    name: { en: 'Dominican Republic', ru: 'Доминиканская Республика' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'EC',
    code: 593,
    code_string: '+593',
    name: { en: 'Ecuador', ru: 'Эквадор' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'EG',
    code: 20,
    code_string: '+20',
    name: { en: 'Egypt', ru: 'Египет' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'SV',
    code: 503,
    code_string: '+503',
    name: { en: 'El Salvador', ru: 'Сальвадор' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'ER',
    code: 291,
    code_string: '+291',
    name: { en: 'Eritrea', ru: 'Эритрея' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'EE',
    code: 372,
    code_string: '+372',
    name: { en: 'Estonia', ru: 'Эстония' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'FJ',
    code: 679,
    code_string: '+679',
    name: { en: 'Fiji', ru: 'Фиджи' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'FI',
    code: 358,
    code_string: '+358',
    name: { en: 'Finland', ru: 'Финляндия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'FR',
    code: 33,
    code_string: '+33',
    name: { en: 'France', ru: 'Франция' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GA',
    code: 241,
    code_string: '+241',
    name: { en: 'Gabon', ru: 'Габон' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GM',
    code: 220,
    code_string: '+220',
    name: { en: 'Gambia', ru: 'Гамбия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GE',
    code: 995,
    code_string: '+995',
    name: { en: 'Georgia', ru: 'Грузия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'DE',
    code: 49,
    code_string: '+49',
    name: { en: 'Germany', ru: 'Германия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GH',
    code: 233,
    code_string: '+233',
    name: { en: 'Ghana', ru: 'Гана' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GI',
    code: 350,
    code_string: '+350',
    name: { en: 'Gibraltar', ru: 'Гибралтар' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GR',
    code: 30,
    code_string: '+30',
    name: { en: 'Greece', ru: 'Греция' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GD',
    code: 1473,
    code_string: '+1473',
    name: { en: 'Grenada', ru: 'Гренада' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GT',
    code: 502,
    code_string: '+502',
    name: { en: 'Guatemala', ru: 'Гватемала' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GN',
    code: 224,
    code_string: '+224',
    name: { en: 'Guinea', ru: 'Гвинея' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GW',
    code: 245,
    code_string: '+245',
    name: { en: 'Guinea-Bissau', ru: 'Гвинея-Бисау' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'HT',
    code: 509,
    code_string: '+509',
    name: { en: 'Haiti', ru: 'Гаити' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'HN',
    code: 504,
    code_string: '+504',
    name: { en: 'Honduras', ru: 'Гондурас' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'HU',
    code: 36,
    code_string: '+36',
    name: { en: 'Hungary', ru: 'Венгрия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'IS',
    code: 354,
    code_string: '+354',
    name: { en: 'Iceland', ru: 'Исландия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'ID',
    code: 62,
    code_string: '+62',
    name: { en: 'Indonesia', ru: 'Индонезия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'IE',
    code: 353,
    code_string: '+353',
    name: { en: 'Ireland', ru: 'Ирландия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'IL',
    code: 972,
    code_string: '+972',
    name: { en: 'Israel', ru: 'Израиль' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'IT',
    code: 39,
    code_string: '+39',
    name: { en: 'Italy', ru: 'Италия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'JM',
    code: 1876,
    code_string: '+1876',
    name: { en: 'Jamaica', ru: 'Ямайка' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'JP',
    code: 81,
    code_string: '+81',
    name: { en: 'Japan', ru: 'Япония' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'JO',
    code: 962,
    code_string: '+962',
    name: { en: 'Jordan', ru: 'Иордания' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'KZ',
    code: 7,
    code_string: '+7',
    name: { en: 'Kazakhstan', ru: 'Казахстан' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'KE',
    code: 254,
    code_string: '+254',
    name: { en: 'Kenya', ru: 'Кения' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'KW',
    code: 965,
    code_string: '+965',
    name: { en: 'Kuwait', ru: 'Кувейт' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'KG',
    code: 996,
    code_string: '+996',
    name: { en: 'Kyrgyzstan', ru: 'Кыргызстан' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'LA',
    code: 856,
    code_string: '+856',
    name: { en: 'Laos', ru: 'Лаос' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'LV',
    code: 371,
    code_string: '+371',
    name: { en: 'Latvia', ru: 'Латвия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'LB',
    code: 961,
    code_string: '+961',
    name: { en: 'Lebanon', ru: 'Ливан' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'LR',
    code: 231,
    code_string: '+231',
    name: { en: 'Liberia', ru: 'Либерия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'LI',
    code: 423,
    code_string: '+423',
    name: { en: 'Liechtenstein', ru: 'Лихтенштейн' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'LT',
    code: 370,
    code_string: '+370',
    name: { en: 'Lithuania', ru: 'Литва' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'LU',
    code: 352,
    code_string: '+352',
    name: { en: 'Luxembourg', ru: 'Люксембург' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MO',
    code: 853,
    code_string: '+853',
    name: { en: 'Macao', ru: 'Макао' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MV',
    code: 960,
    code_string: '+960',
    name: { en: 'Maldives', ru: 'Мальдивская Республика' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'ML',
    code: 223,
    code_string: '+223',
    name: { en: 'Mali', ru: 'Мали' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MT',
    code: 356,
    code_string: '+356',
    name: { en: 'Malta', ru: 'Мальта' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MU',
    code: 230,
    code_string: '+230',
    name: { en: 'Mauritius', ru: 'Маврикий' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MX',
    code: 52,
    code_string: '+52',
    name: { en: 'Mexico', ru: 'Мексика' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'FM',
    code: 691,
    code_string: '+691',
    name: { en: 'Micronesia', ru: 'Микронезия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MD',
    code: 373,
    code_string: '+373',
    name: { en: 'Moldova', ru: 'Молдова' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MC',
    code: 377,
    code_string: '+377',
    name: { en: 'Monaco', ru: 'Монако' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MA',
    code: 212,
    code_string: '+212',
    name: { en: 'Morocco', ru: 'Марокко' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MZ',
    code: 258,
    code_string: '+258',
    name: { en: 'Mozambique', ru: 'Мозамбик' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MM',
    code: 95,
    code_string: '+95',
    name: { en: 'Myanmar (Burma)', ru: 'Бирма (Мьянма)' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'NA',
    code: 264,
    code_string: '+264',
    name: { en: 'Namibia', ru: 'Намибия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'NL',
    code: 31,
    code_string: '+31',
    name: { en: 'Netherlands', ru: 'Нидерланды' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'NZ',
    code: 64,
    code_string: '+64',
    name: { en: 'New Zealand', ru: 'Новая Зеландия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'NI',
    code: 505,
    code_string: '+505',
    name: { en: 'Nicaragua', ru: 'Никарагуа' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'NE',
    code: 227,
    code_string: '+227',
    name: { en: 'Niger', ru: 'Нигер' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'NG',
    code: 234,
    code_string: '+234',
    name: { en: 'Nigeria', ru: 'Нигерия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'MK',
    code: 389,
    code_string: '+389',
    name: { en: 'North Macedonia', ru: 'Северная Македония' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'NO',
    code: 47,
    code_string: '+47',
    name: { en: 'Norway', ru: 'Норвегия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'OM',
    code: 968,
    code_string: '+968',
    name: { en: 'Oman', ru: 'Оман' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'PK',
    code: 92,
    code_string: '+92',
    name: { en: 'Pakistan', ru: 'Пакистан' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'PA',
    code: 507,
    code_string: '+507',
    name: { en: 'Panama', ru: 'Панама' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'PG',
    code: 675,
    code_string: '+675',
    name: { en: 'Papua New Guinea', ru: 'Папуа-Новая Гвинея' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'PY',
    code: 595,
    code_string: '+595',
    name: { en: 'Paraguay', ru: 'Парагвай' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'PE',
    code: 51,
    code_string: '+51',
    name: { en: 'Peru', ru: 'Перу' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'PH',
    code: 63,
    code_string: '+63',
    name: { en: 'Philippines', ru: 'Филиппины' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'PL',
    code: 48,
    code_string: '+48',
    name: { en: 'Poland', ru: 'Польша' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'PT',
    code: 351,
    code_string: '+351',
    name: { en: 'Portugal', ru: 'Португалия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'QA',
    code: 974,
    code_string: '+974',
    name: { en: 'Qatar', ru: 'Катар' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'KR',
    code: 82,
    code_string: '+82',
    name: { en: 'Republic of Korea', ru: 'Южная Корея' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'RO',
    code: 40,
    code_string: '+40',
    name: { en: 'Romania', ru: 'Румыния' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'RU',
    code: 7,
    code_string: '+7',
    name: { en: 'Russia', ru: 'Россия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'RW',
    code: 250,
    code_string: '+250',
    name: { en: 'Rwanda', ru: 'Руанда' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'WS',
    code: 685,
    code_string: '+685',
    name: { en: 'Samoa', ru: 'Самоа' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'SM',
    code: 378,
    code_string: '+378',
    name: { en: 'San Marino', ru: 'Сан-Марино' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'SN',
    code: 221,
    code_string: '+221',
    name: { en: 'Senegal', ru: 'Сенегал' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'RS',
    code: 381,
    code_string: '+381',
    name: { en: 'Serbia', ru: 'Сербия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'SC',
    code: 248,
    code_string: '+248',
    name: { en: 'Seychelles', ru: 'Сейшельские Острова' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'SL',
    code: 232,
    code_string: '+232',
    name: { en: 'Sierra Leone', ru: 'Сьерра-Леоне' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'SK',
    code: 421,
    code_string: '+421',
    name: { en: 'Slovakia', ru: 'Словакия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'SI',
    code: 386,
    code_string: '+386',
    name: { en: 'Slovenia', ru: 'Словения' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'SB',
    code: 677,
    code_string: '+677',
    name: { en: 'Solomon Islands', ru: 'Соломоновы Острова' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'SO',
    code: 252,
    code_string: '+252',
    name: { en: 'Somalia', ru: 'Сомали' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'ZA',
    code: 27,
    code_string: '+27',
    name: { en: 'South Africa', ru: 'Южно-Африканская Республика' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'ES',
    code: 34,
    code_string: '+34',
    name: { en: 'Spain', ru: 'Испания' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'LK',
    code: 94,
    code_string: '+94',
    name: { en: 'Sri Lanka', ru: 'Шри-Ланка' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'KN',
    code: 1869,
    code_string: '+1869',
    name: { en: 'St. Kitts & Nevis', ru: 'Сент-Китс и Невис' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'LC',
    code: 1758,
    code_string: '+1758',
    name: { en: 'St. Lucia', ru: 'Сент-Люсия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'SR',
    code: 597,
    code_string: '+597',
    name: { en: 'Suriname', ru: 'Суринам' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'SE',
    code: 46,
    code_string: '+46',
    name: { en: 'Sweden', ru: 'Швеция' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'CH',
    code: 41,
    code_string: '+41',
    name: { en: 'Switzerland', ru: 'Швейцария' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'TW',
    code: 886,
    code_string: '+886',
    name: { en: 'Taiwan', ru: 'Тайвань' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'TJ',
    code: 992,
    code_string: '+992',
    name: { en: 'Tajikistan', ru: 'Таджикистан' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'TZ',
    code: 255,
    code_string: '+255',
    name: { en: 'Tanzania', ru: 'Танзания' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'TG',
    code: 228,
    code_string: '+228',
    name: { en: 'Togo', ru: 'Того' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'TO',
    code: 676,
    code_string: '+676',
    name: { en: 'Tonga', ru: 'Тонго' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'TT',
    code: 1868,
    code_string: '+1868',
    name: { en: 'Trinidad & Tobago', ru: 'Тринидад и Тобаго' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'TN',
    code: 216,
    code_string: '+216',
    name: { en: 'Tunisia', ru: 'Тунис' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'TM',
    code: 993,
    code_string: '+993',
    name: { en: 'Turkmenistan', ru: 'Туркменистан' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'TC',
    code: 1649,
    code_string: '+1649',
    name: { en: 'Turks & Caicos Islands', ru: 'Теркс и Кайкос' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'TR',
    code: 90,
    code_string: '+90',
    name: { en: 'Turkey', ru: 'Турция' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'UG',
    code: 256,
    code_string: '+256',
    name: { en: 'Uganda', ru: 'Уганда' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'UA',
    code: 380,
    code_string: '+380',
    name: { en: 'Ukraine', ru: 'Украина' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'AE',
    code: 971,
    code_string: '+971',
    name: { en: 'United Arab Emirates', ru: 'Объединенные Арабские Эмираты' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'GB',
    code: 44,
    code_string: '+44',
    name: { en: 'United Kingdom', ru: 'Великобритания' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'UY',
    code: 598,
    code_string: '+598',
    name: { en: 'Uruguay', ru: 'Uruguay' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'UZ',
    code: 998,
    code_string: '+998',
    name: { en: 'Uzbekistan', ru: 'Узбекистан' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'VU',
    code: 678,
    code_string: '+678',
    name: { en: 'Vanuatu', ru: 'Вануату' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'VA',
    code: 3906698,
    code_string: '+3906698',
    name: { en: 'Vatican City', ru: 'Ватикан' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'VE',
    code: 58,
    code_string: '+58',
    name: { en: 'Venezuela', ru: 'Венесуэла' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'VN',
    code: 84,
    code_string: '+84',
    name: { en: 'Vietnam', ru: 'Вьетнам' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'YE',
    code: 967,
    code_string: '+967',
    name: { en: 'Yemen', ru: 'Йемен' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'ZM',
    code: 260,
    code_string: '+260',
    name: { en: 'Zambia', ru: 'Замбия' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'ZW',
    code: 263,
    code_string: '+263',
    name: { en: 'Zimbabwe', ru: 'Зимбабве' },
    permissions: { flash_call: true, sms: true },
  },
  {
    iso: 'IN',
    code: 91,
    code_string: '+91',
    name: { en: 'India', ru: 'Индия' },
    permissions: { flash_call: false, sms: true },
  },
  {
    iso: 'MY',
    code: 60,
    code_string: '+60',
    name: { en: 'Malaysia', ru: 'Малайзия' },
    permissions: { flash_call: false, sms: true },
  },
].sort((a, b) => {
  if (a.iso > b.iso) {
    return 1;
  }

  if (a.iso < b.iso) {
    return -1;
  }

  return 0;
});

export const LOCALES = [
  { iso: 'en-US', name: 'en', title: 'En' },
  { iso: 'es-ES', name: 'es', title: 'Es' },
  { iso: 'fr-FR', name: 'fr', title: 'Fr' },
  { iso: 'hi-HI', name: 'hi', title: 'Hi' },
  { iso: 'id-ID', name: 'id', title: 'Id' },
  { iso: 'pt-BR', name: 'pt', title: 'Pt-Br' },
  { iso: 'ru-RU', name: 'ru', title: 'Ru' },
  { iso: 'tr-TR', name: 'tr', title: 'Tr' },
];

export interface RegionData {
  code_string: string;
  code: number;
  iso: string;
  permissions: {
    sms: boolean;
    flash_call: boolean;
  };
  name: {
    ru: string;
    en: string;
  };
}

export const MODAL_TYPE_DEFAULT = 1;

export const MODAL_TYPE_FULLSCREEN = 2;
