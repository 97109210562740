<div class="confirm">
  <header class="confirm__header">
    <span class="confirm__title uppercase bold">{{ data.title | i18nextEager }}</span>
  </header>
  <section class="confirm__body">
    <span
      class="confirm__text text-1"
      *ngIf="data.text"
    >
      {{ data.text | i18nextEager }}
    </span>
  </section>
  <section class="confirm__actions">
    <cheelee-button
      class="confirm__cancel-btn"
      variant="interactive"
      (clicked)="close()"
    >
      {{ data.cancelButtonText | i18nextEager }}
    </cheelee-button>
    <cheelee-button
      class="confirm__confirm-btn"
      variant="interactive"
      (clicked)="confirm()"
    >
      {{ data.confirmButtonText | i18nextEager }}
    </cheelee-button>
  </section>
</div>
