/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImageImgInner } from './imageImgInner';


/**
 * 1st frame of video and blur hash
 */
export interface VideoFrame1 { 
    /**
     * The blur hash for images
     */
    blur?: string;
    img?: Array<ImageImgInner>;
}

