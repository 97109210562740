import { createAction, props } from '@ngrx/store';
import { AvailableAuthResponse, ScryptParams } from '@api/backend';
import { AuthOAuthTypes, AuthPhoneTypes, AuthSteps, AuthTypes, ErrorResponse, SessionToken } from '@core/models';
import { AuthState } from '@store/auth/auth.reducer';

export const authActions = {
  /**
   * Get Available
   */
  getAvailable: createAction(
    '[Auth] Get Available',
  ),
  getAvailableSuccess: createAction(
    '[Auth] Get Available Success',
    props<{ available: AvailableAuthResponse }>(),
  ),
  getAvailableFailure: createAction(
    '[Auth] Get Available Failure',
    props<{ error: ErrorResponse }>(),
  ),

  /**
   * Code
   */
  getCode: createAction(
    '[Auth] Get Code',
    props<{
      email?: string;
      phone_number?: string;
      auth_type?: AuthTypes;
      auth_phone_type?: AuthPhoneTypes;
    }>(),
  ),
  getCodeSuccess: createAction(
    '[Auth] Get Code Success',
  ),
  getCodeFailure: createAction(
    '[Auth] Get Code Failure',
    props<{ error: ErrorResponse }>(),
  ),

  /**
   * Verify
   */
  verifyCode: createAction(
    '[Auth] Verify Code',
    props<{ code: string }>(),
  ),
  verifyCodeSuccess: createAction(
    '[Auth] Verify Code Success',
    props<{ token: SessionToken, step?: AuthSteps }>(),
  ),
  verifyCodeFailure: createAction(
    '[Auth] Verify Code Failure',
    props<{ error: ErrorResponse }>(),
  ),

  /**
   * Verify User 2FA
   */
  verifyUser2fa: createAction(
    '[Auth] Verify User 2FA',
  ),
  verifyUser2faSuccess: createAction(
    '[Auth] Verify User 2FA Success',
    props<{ token: SessionToken }>(),
  ),
  verifyUser2faFailure: createAction(
    '[Auth] Verify User 2FA Failure',
    props<{ error: ErrorResponse }>(),
  ),

  /**
   * Verify Password
   */
  verifyPassword: createAction(
    '[Auth] Verify Password',
    props<{ password: string }>(),
  ),
  verifyPasswordSuccess: createAction(
    '[Auth] Verify Password Success',
    props<{ id_2fa: string }>(),
  ),
  verifyPasswordFailure: createAction(
    '[Auth] Verify Password Failure',
    props<{ error: ErrorResponse }>(),
  ),

  /**
   * Get OAuth Login
   */
  getOAuthLogin: createAction(
    '[Auth] Get OAuth Login',
    props<{ social: AuthOAuthTypes }>(),
  ),
  getOAuthLoginSuccess: createAction(
    '[Auth] Get OAuth Login Success',
    props<{ redirect_url: string }>(),
  ),
  getOAuthLoginFailure: createAction(
    '[Auth] Get OAuth Login Failure',
    props<{ error: ErrorResponse }>(),
  ),
  /**
   * Get OAuth Callback
   */
  getOAuthCallback: createAction(
    '[Auth] Get OAuth Callback',
    props<{ code: string; auth_oauth_type: AuthOAuthTypes }>(),
  ),
  getOAuthCallbackSuccess: createAction(
    '[Auth] Get OAuth Callback Success',
    props<{ token: SessionToken }>(),
  ),
  getOAuthCallbackFailure: createAction(
    '[Auth] Get OAuth Callback Failure',
    props<{ error: ErrorResponse }>(),
  ),

  /**
   * Scrypt Challenge
   */
  getScryptChallenge: createAction(
    '[Auth] Get Scrypt Challenge',
  ),
  getScryptChallengeSuccess: createAction(
    '[Auth] Get Scrypt Challenge Success',
    props<{ challenge: ScryptParams }>(),
  ),
  getScryptChallengeFailure: createAction(
    '[Auth] Get Scrypt Challenge Failure',
    props<{ error: ErrorResponse }>(),
  ),

  /**
   * Scrypt Hash
   */
  getScryptHash: createAction(
    '[Auth] Get Scrypt Hash',
  ),
  getScryptHashSuccess: createAction(
    '[Auth] Get Scrypt Hash Success',
    props<{ hash: string }>(),
  ),
  getScryptHashFailure: createAction(
    '[Auth] Get Scrypt Hash Failure',
    props<{ error: ErrorResponse }>(),
  ),

  /**
   * Auth State
   */
  updateState: createAction(
    '[Auth] Update State',
    props<{ value: Partial<AuthState> }>(),
  ),
  clearState: createAction(
    '[Auth] Clear State',
  ),
  clearAuthErrors: createAction(
    '[Auth] Clear Auth Errors',
  ),

  /**
   * Open Auth Dialog
   */
  openAuthDialog: createAction(
    '[Auth] Open Auth Dialog',
    props<{ cancelledRoute: string }>(),
  ),

};
