import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal, Signal } from '@angular/core';
import { I18NextString } from '@core/models';
import { ComponentHelperService, OverlayService } from '@core/services';
import { IconComponent } from '@shared/components';
import { RippleDirective, ThrottleClickDirective } from '@shared/directives';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { I18NextModule } from 'angular-i18next';
import { AnalyticsEventsEnum } from '@core/models/analytics.model';
import { AnalyticsDirective } from '@shared/directives/analytics.directive';
import { TabBarFacade } from '@shared/components/tab-bar/tab-bar.facade';

interface TabBarItem {
  label: I18NextString;
  link: Signal<string | undefined>;
  secondOrderLink: Signal<string | undefined>;
  action?: () => void;
  icon: {
    defaultName: string;
    activeName: string;
  };
  eventName: AnalyticsEventsEnum;
}

@Component({
  selector: 'cheelee-tab-bar',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    RippleDirective,
    ThrottleClickDirective,
    RouterLinkActive,
    RouterLink,
    I18NextModule,
    AnalyticsDirective,
  ],
  templateUrl: './tab-bar.component.html',
  styleUrls: [ './tab-bar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ TabBarFacade ],
})
export class TabBarComponent {

  public readonly facade = inject(TabBarFacade);

  private readonly componentHelper = inject(ComponentHelperService);

  public readonly tabBarItems = signal<TabBarItem[]>([
    {
      label: this.getLabel('watch'),
      link: signal(''),
      secondOrderLink: this.facade.secondOrderMediaLink,
      icon: {
        defaultName: this.getSvgIconPath('fire-watch'),
        activeName: this.getSvgIconPath('fire-watch-filled'),
      },
      action: () => {
      },
      eventName: AnalyticsEventsEnum.FeedTap,
    },
    {
      label: this.getLabel('create'),
      action: () => this.overlayService.openGetAppDialog(AnalyticsEventsEnum.CreateTap, 'lee'),
      link: signal(undefined),
      secondOrderLink: signal(undefined),
      icon: {
        defaultName: this.getSvgIconPath('create'),
        activeName: this.getSvgIconPath('create'),
      },
      eventName: AnalyticsEventsEnum.CreateTap,
    },
    {
      label: this.getLabel('earn'),
      link: signal(undefined),
      secondOrderLink: signal(undefined),
      action: () => this.overlayService.openGetAppDialog(AnalyticsEventsEnum.EarnTap, 'lee'),
      icon: {
        defaultName: this.getSvgIconPath('pepper'),
        activeName: this.getSvgIconPath('pepper-filled'),
      },
      eventName: AnalyticsEventsEnum.EarnTap,
    },
    {
      label: this.getLabel('market'),
      link: signal('/market/offers'),
      secondOrderLink: this.facade.secondOrderMarketLink,
      action: () => {},
      icon: {
        defaultName: this.getSvgIconPath('market'),
        activeName: this.getSvgIconPath('market-filled'),
      },
      eventName: AnalyticsEventsEnum.MarketTap,
    },
    {
      label: this.getLabel('profile'),
      link: signal(undefined), // this.facade.currentUserId,
      secondOrderLink: signal(undefined),
      icon: {
        defaultName: this.getSvgIconPath('profile'),
        activeName: this.getSvgIconPath('profile-filled'),
      },
      action: () => {
        this.overlayService.openGetAppDialog(AnalyticsEventsEnum.MyProfileTap, 'lee');
        // if (this.facade.currentUserId()) {
        //   if (
        //     this.facade.viewSource() === ViewSources.UserProfile &&
        //     this.facade.userIdParam() !== this.facade.currentUserId()
        //   ) {
        //     this.componentHelper.reloadComponent(false, `/${ this.facade.currentUserId() }`);
        //   }
        // } else {
        //   this.facade.updateAuthState({ cancelledRoute: EMPTY_STRING });
        //   this.overlayService.openAuthDialog();
        // }
      },
      eventName: AnalyticsEventsEnum.MyProfileTap,
    },
  ]);

  constructor(
    private readonly overlayService: OverlayService,
  ) {
  }

  public onItemClick(item: TabBarItem, event: Event): void {
    if (!item.link) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (item.action) {
      item.action();
    }
  }

  private getLabel(key: string): I18NextString {
    return `tabbar:${ key }`;
  }

  private getSvgIconPath(iconName: string): string {
    return `tabbar/${ iconName }.svg`;
  }

  protected readonly analyticsEvents = AnalyticsEventsEnum;
}
