/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetOfferViews500Response { 
    error_key?: GetOfferViews500Response.ErrorKeyEnum;
}
export namespace GetOfferViews500Response {
    export type ErrorKeyEnum = 'internal_error';
    export const ErrorKeyEnum = {
        InternalError: 'internal_error' as ErrorKeyEnum
    };
}


