import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'markdown',
  standalone: true,
})
export class MarkdownPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, prefix: string = ''): SafeHtml {
    if (!value) return '';

    const escapedPrefix = prefix.replace(/[*_\[\]{}()#+\-.!`~\\]/g, '\\$&'); // Экранирование Markdown-символов

    const htmlContent: string | Promise<string> = marked.parse((prefix ? escapedPrefix + ' ' : '') + value);

    if (htmlContent instanceof Promise) {
      throw new Error('Async markdown rendering is not supported in this synchronous pipe.');
    }

    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

}
