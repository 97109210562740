import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';


export function phoneNumberValidator(regionCode: string): ValidatorFn {
  return (control: AbstractControl<string>): ValidationErrors | null => {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const exampleNumber = phoneNumberUtil.getExampleNumber(regionCode);
    const phoneNumberMinLength = exampleNumber.getNationalNumber()?.toString().length ?? 0;
    const isMinLength = control.value?.length >= phoneNumberMinLength;

    let inputPhoneNumber: string = control.value;

    if (!isMinLength) {
      return { invalidPhoneNumber: true };
    }

    // Special checking for systems phone numbers which can start from "+7000"
    if (regionCode === 'RU' && inputPhoneNumber.startsWith('000') && !/^0+$/.test(inputPhoneNumber)) {
      inputPhoneNumber = inputPhoneNumber.replace('000', '919');
    }

    const isValid = phoneNumberUtil.isValidNumber(phoneNumberUtil.parseAndKeepRawInput(inputPhoneNumber, regionCode));

    return isValid ? null : { invalidPhoneNumber: true };
  };
}
