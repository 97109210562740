/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MinMaxRangeDecimal } from './minMaxRangeDecimal';
import { Image } from './image';


export interface TokenObject { 
    currency?: string;
    image?: Image;
    range?: MinMaxRangeDecimal;
}

