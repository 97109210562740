/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OfferPrice } from './offerPrice';


export interface OfferSnippet { 
    asset_type?: OfferSnippet.AssetTypeEnum;
    rarity_name?: string | null;
    price?: OfferPrice;
    /**
     * The URL of the image
     */
    image_url?: string;
}
export namespace OfferSnippet {
    export type AssetTypeEnum = 'glasses' | 'common' | 'lootbox' | 'coin';
    export const AssetTypeEnum = {
        Glasses: 'glasses' as AssetTypeEnum,
        Common: 'common' as AssetTypeEnum,
        Lootbox: 'lootbox' as AssetTypeEnum,
        Coin: 'coin' as AssetTypeEnum
    };
}


