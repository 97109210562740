import { Injectable, Signal } from '@angular/core';

import { Store } from '@ngrx/store';

import { AuthOAuthTypes, AuthPhoneTypes, AuthTypes, User } from '@core/models';
import { authActions, authSelectors, AuthState } from '@store/auth';
import { userSelectors } from '@store/user';
import { Observable } from 'rxjs';

@Injectable()
export class AuthFacade {

  public readonly currentUser$: Observable<User | undefined> = this.store.select(userSelectors.selectUserData);

  public readonly currentUser: Signal<User | undefined> = this.store.selectSignal(userSelectors.selectUserData);

  public readonly availableOAuths$ = this.store.select(authSelectors.selectAvailable);

  public readonly regionList = this.store.selectSignal(authSelectors.selectRegionList);

  public readonly cancelledRoute = this.store.selectSignal(authSelectors.selectCancelledRoute);

  public readonly authState$ = this.store.select(authSelectors.selectAuthState);

  public readonly region$ = this.store.select(authSelectors.selectRegion);

  public readonly error$ = this.store.select(authSelectors.selectError);

  public readonly step$ = this.store.select(authSelectors.selectStep);

  public readonly isLoading$ = this.store.select(authSelectors.selectIsLoading);

  constructor(private readonly store: Store) {}

  public updateAuthState(value: Partial<AuthState>): void {
    this.store.dispatch(authActions.updateState({ value }));
  }

  public getOAuthLogin(social: AuthOAuthTypes): void {
    this.store.dispatch(authActions.getOAuthLogin({ social }));
  }

  public clearAuthErrors(): void {
    this.store.dispatch(authActions.clearAuthErrors());
  }

  public getCode(
    data: Partial<{ email: string; phone_number: string; auth_type: AuthTypes; auth_phone_type: AuthPhoneTypes }>,
  ): void {
    this.store.dispatch(
      authActions.getCode({
        email: data.email,
        phone_number: data.phone_number,
        auth_type: data.auth_type,
        auth_phone_type: data.auth_phone_type,
      }),
    );
  }

  public verifyCode(code: string): void {
    this.store.dispatch(authActions.verifyCode({ code }));
  }

  public verifyPassword(password: string): void {
    this.store.dispatch(authActions.verifyPassword({ password }));
  }

  public clearState(): void {
    this.store.dispatch(authActions.clearState());
  }

  public getAvailableOAuthTypes(): void {
    this.store.dispatch(authActions.getAvailable());
  }

  public getOAuthCallback(code: string, state: string): void {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const auth_oauth_type = state.replace('from_', '') as AuthOAuthTypes;
    this.store.dispatch(authActions.getOAuthCallback({ code, auth_oauth_type }));
  }
}
