import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { I18NextModule } from 'angular-i18next';

import { ConfirmModalData } from '@core/models';
import { OverlayService } from '@core/services';
import { ModalsNames } from '@data/const';
import { ButtonComponent } from '@shared/components';

@Component({
  selector: 'cheelee-confirm-overlay',
  standalone: true,
  imports: [CommonModule, ButtonComponent, I18NextModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  public data = this.overlayService.getModalData<ConfirmModalData>(ModalsNames.Confirm);

  private modalRef = this.overlayService.getModalInstance(ModalsNames.Confirm);

  constructor(private readonly overlayService: OverlayService) {}

  public close(): void {
    if (this.modalRef) {
      this.modalRef.onClose.emit(false);
    }

    this.overlayService.close(ModalsNames.Confirm);
  }

  public confirm(): void {
    if (this.modalRef) {
      this.modalRef.onClose.emit(true);
    }

    this.overlayService.close(ModalsNames.Confirm);
  }
}
