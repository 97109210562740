import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { I18NextModule, I18NextService } from 'angular-i18next';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { isNil } from 'lodash';
import { take, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';

import { RouterFacade } from '@core/facades';
import { OverlayService } from '@core/services';
import { EMPTY_STRING, ModalsNames } from '@data/const';
import {
  ButtonComponent,
  DialogWrapperComponent,
  IconComponent,
  LoaderComponent, SpinnerComponent,
  SupportContactsComponent,
} from '@shared/components';
import { AuthState } from '@store/auth';
import { AuthFacade } from './auth.facade';
import { AUTH_CODE_LENGTH, AuthOAuthTypes, AuthPhoneTypes, AuthSteps, AuthTypes } from '@core/models';
import { emailValidator, phoneNumberValidator } from '@app/shared/validators';
import { RegionData } from '@data/data';
import { RippleDirective } from '@shared/directives';
import { NgForOf, NgIf, NgOptimizedImage, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { InputComponent } from '@shared/components/input/input.component';
import { CodeInputComponent } from '@shared/components/code-input/code-input.component';

interface AuthForm {
  phone_number: FormControl<string | null>;
  email: FormControl<string | null>;
  code: FormControl<string | null>;
  password: FormControl<string | null>;
}

@Component({
  selector: 'cheelee-auth',
  standalone: true,
  templateUrl: './auth.component.html',
  styleUrls: [ './auth.component.scss' ],
  providers: [ AuthFacade ],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    DialogWrapperComponent,
    RippleDirective,
    IconComponent,
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
    ReactiveFormsModule,
    NgOptimizedImage,
    I18NextModule,
    NgIf,
    NgForOf,
    LoaderComponent,
    ButtonComponent,
    InputComponent,
    SpinnerComponent,
    CodeInputComponent,
    CountdownComponent,
  ],
})
export class AuthComponent implements OnInit {

  private readonly destroyRef = inject(DestroyRef);

  private readonly authFacade = inject(AuthFacade);

  constructor(
    private formBuilder: FormBuilder,
    private readonly overlayService: OverlayService,
    private readonly i18NextService: I18NextService,
    private readonly routerFacade: RouterFacade,
  ) {}


  @ViewChild('cd', { static: false })
  public countdown?: CountdownComponent;

  // @ViewChild('googleBtn')
  // public googleSignInBtn!: ElementRef<HTMLElement>;

  public region = toSignal(this.authFacade.region$.pipe(tap((region) => this.onSelectCountryCode(region))));

  public isPasswordInputVisible = false;

  public isCountryDropdownActive = false;

  public authPhoneType = AuthPhoneTypes;

  public authType = AuthTypes; // Ensure AuthTypes is exported from '@core/models'

  public formGroup = this.formBuilder.group<AuthForm>({
    phone_number: this.formBuilder.control(null, [Validators.required, Validators.pattern('^[0-9]*$')]),
    email: this.formBuilder.control(null, [Validators.required, emailValidator()]),
    code: this.formBuilder.control(null, [Validators.required, Validators.pattern('^[0-9]*$')]),
    password: this.formBuilder.control(null, [Validators.required]),
  });

  public AuthStep = AuthSteps;

  public phoneNumberMinLength = 10;

  public codeMaxLength = 4;

  public countdownConfig: CountdownConfig = {
    leftTime: 60,
    // @ts-ignore
    formatDate: (date) => {
      const value = date.date / 1000;
      // const minutes = Math.floor(value / 60) % 60;
      // const seconds = Math.floor(value) % 60;
      return value === 0
        ? ''
        : // : `${minutes > 9 ? minutes : '0' + minutes}m&nbsp;:&nbsp;${seconds > 9 ? seconds : '0' + seconds}s`;
        value;
    },
  };

  public countdownState?: CountdownEvent;

  public readonly available = toSignal(this.authFacade.availableOAuths$.pipe(filter((value) => !!value)), {
    initialValue: [],
  });

  public readonly regionList = this.authFacade.regionList;

  public readonly isLoading = toSignal(
    this.authFacade.isLoading$.pipe(tap((isLoading) => this.formGroup[isLoading ? 'disable' : 'enable']())),
    { initialValue: false },
  );

  public authState = toSignal(
    this.authFacade.authState$.pipe(
      tap((state) => {
        this.currentStep = state.step;

        if (state.step === AuthSteps.DONE) {
          const route = this.authFacade.cancelledRoute();

          if (!isNil(route)) {
            if (route === EMPTY_STRING) {
              this.authFacade.currentUser$
                .pipe(
                  filter((user) => !!user),
                  takeUntilDestroyed(this.destroyRef),
                )
                .subscribe((user) => void this.routerFacade.navigate({ path: user?.user_id ?? '/' }));
            } else {
              setTimeout(() => void this.routerFacade.navigate({ path: route }));
            }
          }

          this.closeDialog();
        }
      }),
    ),
  );

  public stateError = toSignal(this.authFacade.error$);

  public selectedRegion: RegionData | undefined;

  private currentStep?: AuthSteps;


  public ngOnInit(): void {
    this.init();
  }

  public onSetStep(step: number, auth_type?: AuthTypes): void {
    this.authFacade.updateAuthState(!auth_type ? { step, error: undefined } : { step, auth_type, error: undefined });
  }

  public closeDialog(): void {
    const modalInstance = this.overlayService.getModalInstance(ModalsNames.Auth);

    if (modalInstance) {
      modalInstance.onAnyCloseEventFinished
        .pipe(take(1), takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.authFacade.clearState());
    }

    this.overlayService.close(ModalsNames.Auth);
  }

  public openSupportCurtain(): void {
    this.overlayService.openCurtain<SupportContactsComponent>({
      title: 'common:contact us',
      component: SupportContactsComponent,
      isStretchDisabled: true,
    });
  }

  public authWithSocial(social: AuthOAuthTypes): void {
    // TODO: Need to test angularx-social-login library
    // switch (social) {
    //   case 'google':
    //     // Open google sign in popup with custom button
    //     this.googleSignInBtn.nativeElement.querySelector('div > div > div').click();
    //     break;
    //
    //   case 'vk':
    //     void this.authService.signIn(VKLoginProvider.PROVIDER_ID).then();
    //     break;
    //
    //   case 'facebook':
    //     void this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then();
    //     break;
    //
    //   case 'apple':
    //     void this.authService.signIn(AppleLoginProvider.PROVIDER_ID).then();
    //     break;
    // }

    this.authFacade.getOAuthLogin(social);
  }

  public disableGetCodeReason(authPhoneType: AuthPhoneTypes, state: AuthState): boolean {
    return !!this.formGroup.get('phone_number')?.invalid || state.loading || !!state.error;
  }

  public onSubmit(code?: string): void {
    if (code) {
      this.formGroup.controls.code.setValue(code, { emitEvent: false });
    }

    const state = this.authState();

    if (!state) {
      return;
    }

    switch (state.step) {
      case AuthSteps.CONTACT:
        if (state.auth_type === AuthTypes.PHONE) {
          this.onGetPhoneCode(AuthPhoneTypes.SMS);
        } else {
          this.onGetEmailCode();
        }

        break;

      case AuthSteps.CODE:
        this.onCodeVerify();
        break;

      case AuthSteps.PASSWORD:
        this.onPasswordCheck();
        break;
    }
  }

  public onSelectCountryCode(region: RegionData): void {
    this.authFacade.updateAuthState({ region });

    if (!region.permissions.flash_call) {
      this.resetCodeValidators(AuthTypes.PHONE, this.authPhoneType.SMS);
    } else {
      this.resetCodeValidators(AuthTypes.PHONE, this.authPhoneType.FLASH_CALL);
    }

    this.resetPhoneValidators(region);
  }

  public onGetPhoneCode(auth_phone_type?: AuthPhoneTypes): void {
    const region = this.authState()!.region.code_string;
    const phoneNumber = String(region + this.formGroup.get('phone_number')?.value);
    this.resetCodeValidators(AuthTypes.PHONE, auth_phone_type);
    this.authFacade.getCode({ phone_number: phoneNumber, auth_type: AuthTypes.PHONE, auth_phone_type });
  }

  public onGetEmailCode(): void {
    const email = String(this.formGroup.controls.email.value);
    this.resetCodeValidators(AuthTypes.EMAIL);
    this.authFacade.getCode({ email, auth_type: AuthTypes.EMAIL });
  }

  public resendPhoneCode(auth_phone_type?: AuthPhoneTypes): void {
    this.formGroup.get('code')?.setValue(null);
    this.onGetPhoneCode(auth_phone_type);
    this.countdown?.restart();
  }

  public resendEmailCode(): void {
    this.formGroup.get('code')?.setValue(null);
    this.onGetEmailCode();
    this.countdown?.restart();
  }

  public onChangePhoneNumber() {
    this.formGroup.get('code')?.setValue(null);
    this.formGroup.get('phone_number')?.setValue(null);
    this.authFacade.updateAuthState({
      step: AuthSteps.CONTACT,
      error: undefined,
      code: undefined,
      phone_number: undefined,
    });
  }

  public onChangeEmail() {
    this.formGroup.get('code')?.setValue(null);
    this.formGroup.get('email')?.setValue(null);
    this.authFacade.updateAuthState({ step: AuthSteps.CONTACT, error: undefined, code: undefined, email: undefined });
  }

  public onCodeVerify(): void {
    const code = String(this.formGroup.get('code')?.value);
    this.authFacade.verifyCode(code);
  }

  public onPasswordCheck(): void {
    const password = String(this.formGroup.get('password')?.value);
    this.authFacade.verifyPassword(password);
  }

  public countdownEvent(event: CountdownEvent): void {
    this.countdownState = event;
  }

  public onAgreementChange($event: Event): void {
    this.authFacade.updateAuthState({ isResident: ($event.target as HTMLInputElement)?.checked });
  }

  public switchVisible(): void {
    this.isPasswordInputVisible = !this.isPasswordInputVisible;
  }

  public goBack(): void {
    this.authFacade.updateAuthState({ error: undefined });

    switch (this.authState()?.step) {
      case AuthSteps.START:
        this.closeDialog();
        break;

      case AuthSteps.CONTACT:
        this.formGroup.controls.email.reset('');
        this.authFacade.updateAuthState({ step: AuthSteps.START, error: undefined, code: undefined, email: undefined });
        break;

      case AuthSteps.CODE:
        this.formGroup.controls.email.reset('');
        this.authFacade.updateAuthState({ step: AuthSteps.CONTACT });
        break;

      case AuthSteps.PASSWORD: {
        const isConfirmed$ = this.overlayService.openConfirmModal({
          title: 'auth:leave',
          text: 'auth:just_enter_password',
          cancelButtonText: 'actions:cancel',
          confirmButtonText: 'actions:leave_btn',
        });

        isConfirmed$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe((isConfirmed) => {
          if (isConfirmed) {
            this.closeDialog();
          }
        });
        break;
      }
    }
  }

  private init(): void {
    // this.dialogRef
    //   ?.closureRequested()
    //   .pipe(takeUntilDestroyed(this.destroyRef))
    //   .subscribe(() => {
    //     if (this.state.getValue().auth.step === AuthSteps.PASSWORD) {
    //       this.confirm?.openPopup();
    //     } else {
    //       this.dialogRef.closeImmediately();
    //     }
    //   });
    // this.authFacade.getAvailableOAuthTypes();
  }

  private resetCodeValidators(auth_type: AuthTypes, auth_phone_type?: AuthPhoneTypes) {
    this.authFacade.updateAuthState({ auth_phone_type });
    this.codeMaxLength =
      auth_phone_type === AuthPhoneTypes.FLASH_CALL && auth_type !== AuthTypes.EMAIL
        ? AUTH_CODE_LENGTH[AuthPhoneTypes.FLASH_CALL]
        : AUTH_CODE_LENGTH[AuthPhoneTypes.SMS];

    this.formGroup.get('code')?.clearValidators();
    this.formGroup
      .get('code')
      ?.setValidators([
        Validators.required,
        Validators.minLength(this.codeMaxLength),
        Validators.maxLength(this.codeMaxLength),
      ]);
    this.formGroup.get('code')?.updateValueAndValidity();
  }

  private resetPhoneValidators(region: RegionData) {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneNumberUtil.getExampleNumber(region.iso);
    this.phoneNumberMinLength = phoneNumber.getNationalNumber()?.toString().length ?? 0;

    if (this.phoneNumberMinLength > 0) {
      this.formGroup.get('phone_number')?.clearValidators();
      this.formGroup
        .get('phone_number')
        ?.setValidators([
          Validators.required,
          Validators.minLength(this.phoneNumberMinLength),
          phoneNumberValidator(this.authState()!.region.iso),
        ]);
      this.formGroup.get('phone_number')?.updateValueAndValidity();
    }
  }
}
