/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetOfferViews404Response { 
    error_key?: GetOfferViews404Response.ErrorKeyEnum;
}
export namespace GetOfferViews404Response {
    export type ErrorKeyEnum = 'page.not_found';
    export const ErrorKeyEnum = {
        PageNotFound: 'page.not_found' as ErrorKeyEnum
    };
}


