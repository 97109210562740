/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContainerForStatusObject } from './containerForStatusObject';


export interface StatusInfo { 
    type?: StatusInfo.TypeEnum;
    object?: ContainerForStatusObject;
}
export namespace StatusInfo {
    export type TypeEnum = 'pending' | 'success' | 'declined';
    export const TypeEnum = {
        Pending: 'pending' as TypeEnum,
        Success: 'success' as TypeEnum,
        Declined: 'declined' as TypeEnum
    };
}


