/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PurchaseExternal400Response { 
    error_key?: PurchaseExternal400Response.ErrorKeyEnum;
}
export namespace PurchaseExternal400Response {
    export type ErrorKeyEnum = 'invalid_price';
    export const ErrorKeyEnum = {
        InvalidPrice: 'invalid_price' as ErrorKeyEnum
    };
}


