import { Environment } from '@core/models';
import { VERSION } from '@data/config';

export const environment: Environment = {
  production: false,
  base_url: 'https://chl-api-int.talala.la/api',
  market_base_url: 'https://chl-api-int.talala.la/api/v1/marketplace',
  cdn_url: 'https://cdn-chl-int.talala.la',
  name: 'integration',
  app_name: 'Cheelee WEB App',
  app_version: VERSION,
  app_build: '0',
  app_type: 'watch_to_earn',
  sentryClientDsn: 'https://7a6363a5e766489b80450f19246fdc02@sentry-dev.talala.la/104',
  fingerprintApiKey: 'D8ZyPA0NT68r4pUtfznh',
  ymConfig: {
    id: 98975091,
  },
  redirectServiceUrl: 'https://app.getlee.co',
  firebaseConfig: {
    apiKey: 'AIzaSyAIJWRrdbmblZPBOTW0PyurvyXHwWBTln4',
    authDomain: 'cheelee-prod.firebaseapp.com',
    projectId: 'cheelee-prod',
    storageBucket: 'cheelee-prod.firebasestorage.app',
    messagingSenderId: '684592764731',
    appId: '1:684592764731:web:6b6466157ec002d486c91e',
    measurementId: 'G-FDCGNS0HV3',
  },
};
