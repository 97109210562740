import { AuthOAuthTypes, AuthPhoneTypes, AuthSteps, AuthTypes, ErrorResponse } from '@core/models';
import { createReducer, on } from '@ngrx/store';
import { authActions } from '@store/auth/auth.actions';
import { pick } from 'lodash';
import { REGION_LIST, RegionData } from '@data/data';

export const authFeatureKey = 'auth';

export interface AuthState {
  region: RegionData;
  phone_number?: string;
  email?: string;
  code?: string;
  password?: string;
  step: AuthSteps;
  auth_type?: AuthTypes;
  auth_phone_type?: AuthPhoneTypes;
  auth_oauth_type?: AuthOAuthTypes;
  available: Array<AuthOAuthTypes> | null;
  regionList: Array<RegionData>;
  isResident: boolean;
  hash?: string;
  id_2fa?: string;
  error?: ErrorResponse;
  loading: boolean;
  cancelledRoute: string | null;
}

export const initialState: AuthState = {
  region: REGION_LIST[0],
  phone_number: undefined,
  email: undefined,
  code: undefined,
  password: undefined,
  step: AuthSteps.START,
  auth_type: undefined,
  auth_phone_type: undefined,
  auth_oauth_type: undefined,
  available: null,
  regionList: REGION_LIST,
  isResident: true,
  hash: undefined,
  id_2fa: undefined,
  error: undefined,
  loading: false,
  cancelledRoute: null,
};

export const authReducer = createReducer(
  initialState,
  on(
    authActions.updateState,
    (state, { value }): AuthState => ({
      ...state,
      ...pick(value, Object.keys(state)),
    }),
  ),

  on(
    authActions.clearState,
    (): AuthState => ({
      ...initialState,
    }),
  ),

  on(
    authActions.clearAuthErrors,
    (state): AuthState => ({
      ...state,
      error: undefined,
    }),
  ),

  on(
    authActions.getCode,
    (state, { phone_number, email, auth_type, auth_phone_type }): AuthState => ({
      ...state,
      auth_type,
      auth_phone_type,
      phone_number,
      email,
      code: undefined,
      loading: true,
    }),
  ),
  on(
    authActions.getCodeSuccess,
    (state): AuthState => ({
      ...state,
      step: AuthSteps.CODE,
      loading: false,
    }),
  ),
  on(
    authActions.getCodeFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
      loading: false,
    }),
  ),

  on(
    authActions.verifyCode,
    (state, { code }): AuthState => ({
      ...state,
      code,
      error: undefined,
      loading: true,
    }),
  ),
  on(
    authActions.verifyCodeSuccess,
    (state, { step }): AuthState => ({
      ...state,
      step: step ?? state.step,
      loading: false,
    }),
  ),
  on(
    authActions.verifyCodeFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
      loading: false,
    }),
  ),

  on(
    authActions.verifyPassword,
    (state, { password }): AuthState => ({
      ...state,
      password,
      loading: true,
    }),
  ),
  on(
    authActions.verifyPasswordSuccess,
    (state, { id_2fa }): AuthState => ({
      ...state,
      id_2fa,
      loading: false,
    }),
  ),
  on(
    authActions.verifyPasswordFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
      loading: false,
    }),
  ),

  on(
    authActions.verifyUser2fa,
    (state): AuthState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    authActions.verifyUser2faSuccess,
    (state): AuthState => ({
      ...state,
      step: AuthSteps.DONE,
      loading: false,
    }),
  ),
  on(
    authActions.verifyUser2faFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
      loading: false,
    }),
  ),

  on(
    authActions.getScryptChallenge,
    (state): AuthState => ({
      ...state,
    }),
  ),
  on(
    authActions.getScryptChallengeSuccess,
    (state): AuthState => ({
      ...state,
    }),
  ),
  on(
    authActions.getScryptChallengeFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
    }),
  ),

  on(
    authActions.getScryptHash,
    (state): AuthState => ({
      ...state,
    }),
  ),
  on(
    authActions.getScryptHashSuccess,
    (state, { hash }): AuthState => ({
      ...state,
      hash,
    }),
  ),
  on(
    authActions.getScryptHashFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
    }),
  ),

  on(
    authActions.getAvailable,
    (state): AuthState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    authActions.getAvailableSuccess,
    (state, { available }): AuthState => ({
      ...state,
      available: available.data.available as AuthOAuthTypes[],
      region: REGION_LIST.find((region) => region.iso === available.data.country_code) ?? REGION_LIST[0],
      loading: false,
    }),
  ),
  on(
    authActions.getAvailableFailure,
    (state): AuthState => ({
      ...state,
      available: [],
      loading: false,
    }),
  ),

  on(
    authActions.getOAuthLogin,
    (state): AuthState => ({
      ...state,
    }),
  ),
  on(
    authActions.getOAuthLoginSuccess,
    (state): AuthState => ({
      ...state,
    }),
  ),
  on(
    authActions.getOAuthLoginFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
    }),
  ),

  on(
    authActions.getOAuthCallback,
    (state, { code, auth_oauth_type }): AuthState => ({
      ...state,
      step: AuthSteps.PENDING,
      auth_type: AuthTypes.OAUTH,
      auth_oauth_type,
      code,
    }),
  ),
  on(
    authActions.getOAuthCallbackSuccess,
    (state): AuthState => ({
      ...state,
    }),
  ),
  on(
    authActions.getOAuthCallbackFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
    }),
  ),
  on(
    authActions.openAuthDialog,
    (state, { cancelledRoute }): AuthState => ({
      ...state,
      cancelledRoute,
    }),
  ),
);
