/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Conflict error on purchase_internal method
 */
export interface PurchaseInternalConflictError { 
    error_key?: PurchaseInternalConflictError.ErrorKeyEnum;
}
export namespace PurchaseInternalConflictError {
    export type ErrorKeyEnum = 'wallet.starting_glasses_not_broken';
    export const ErrorKeyEnum = {
        WalletStartingGlassesNotBroken: 'wallet.starting_glasses_not_broken' as ErrorKeyEnum
    };
}


