import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_ID, NgModule } from '@angular/core';

import { JwtModule } from '@auth0/angular-jwt';
import { I18NextModule } from 'angular-i18next';
import player from 'lottie-web/build/player/lottie_svg';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';

import { NgxSmartModalModule } from 'ngx-smart-modal';

import { ApiModule as BackendApiModule, Configuration as BackendConfiguration } from '@api/backend';
import { ApiModule as MarketplaceApiModule, Configuration as MarketplaceConfiguration } from '@api/marketplace';
import { getCookie } from '@core/helpers';
import { I18N_PROVIDERS } from '@core/i18n/i18n';
import { ErrorInterceptor, JwtInterceptor } from '@core/interceptors';
import { INTERNET_CONNECTION_PROVIDER } from '@core/providers';
import { BREAKPOINTS_PROVIDER } from '@core/providers/breakpoint.provider';
import { AuthRepository, UsersRepository } from '@core/repositories';
import { MediaRepository } from '@core/repositories/media.repository';
import { TOKEN_NAME } from '@data/config';
import { AuthWebRepository } from '@data/repositories/auth-web.repository';
import { MediaWebRepository } from '@data/repositories/media-web.repository';
import { UsersWebRepository } from '@data/repositories/users-web.repository';
import { environment } from '@environments/environment';
import { MediaModule } from '@modules/media/media.module';
import { HeaderComponent } from '@shared/components/header/header.component';
import { ToastrModule } from 'ngx-toastr';
import { SnackbarComponent } from '@shared/components';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';

// Note we need a separate function as it's required
// by the AOT compiler.

const MODULES = [
  CommonModule,
  NgOptimizedImage,
  MediaModule,
  HeaderComponent,
  // APP_STATE_FEATURE_MODULES,
];

@NgModule({
  imports: [
    JwtModule.forRoot({ config: { tokenGetter: () => getCookie(TOKEN_NAME) } }),
    I18NextModule.forRoot(),
    BackendApiModule.forRoot(
      () => new BackendConfiguration({ basePath: environment.base_url, withCredentials: false }),
    ),
    MarketplaceApiModule.forRoot(
      () => new MarketplaceConfiguration({ basePath: environment.market_base_url, withCredentials: false }),
    ),
    NgxSmartModalModule.forRoot(),
    ToastrModule.forRoot({
      toastComponent: SnackbarComponent,
      preventDuplicates: true,
    }),
    MODULES,
  ],
  exports: MODULES,
  providers: [
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: AuthRepository, useClass: AuthWebRepository },
    { provide: UsersRepository, useClass: UsersWebRepository },
    { provide: MediaRepository, useClass: MediaWebRepository },
    I18N_PROVIDERS,
    BREAKPOINTS_PROVIDER,
    INTERNET_CONNECTION_PROVIDER,
    provideLottieOptions({ player: () => player }),
    provideCacheableAnimationLoader(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
  ],
})
export class CoreModule {}
