import { createAction, props } from '@ngrx/store';

import { ErrorResponse, SessionToken } from '@core/models';

export const sessionActions = {
  /**
   * Get Auth Session
   */
  getAuthSession: createAction(
    '[Session] Get Auth Session',
  ),
  getAuthSessionSuccess: createAction(
    '[Session] Get Auth Session Success',
    props<{ token: SessionToken }>(),
  ),
  getAuthSessionFailure: createAction(
    '[Session] Get Auth Session Failure',
    props<{ error: ErrorResponse }>(),
  ),

  /**
   * Refresh Auth Session
   */
  refreshAuthSession: createAction(
    '[Session] Refresh Auth Session',
  ),
  refreshAuthSessionSuccess: createAction(
    '[Session] Refresh Auth Session Success',
    props<{ token: SessionToken }>(),
  ),
  refreshAuthSessionFailure: createAction(
    '[Session] Refresh Auth Session Failure',
    props<{ error: ErrorResponse }>(),
  ),
  /**
   * Get Auth Token Success
   */
  getAuthTokenSuccess: createAction(
    '[Session] Get Auth Token Success',
    props<{ token: SessionToken }>(),
  ),
  /**
   *  Remove Auth Session
   */
  removeAuthSession: createAction(
    '[Session] Remove Auth Session',
  ),
  removeAuthSessionSuccess: createAction(
    '[Session] Remove Auth Session Success',
  ),
  removeAuthSessionFailure: createAction(
    '[Session] Remove Auth Session Failure',
    props<{ error: ErrorResponse }>(),
  ),
};
