import { OfferCurrency } from '@api/backend';
import { SelectOption } from '@core/models/select.model';

export type MarketOfferCurrency = OfferCurrency;

export function mapCurrencyListToSelectOptions(list: MarketOfferCurrency[]): SelectOption[] {
  return list.filter(currency => currency.code)
    .map(currency => ({
      label: currency.code!,
      value: currency.code!,
      icon: currency.image?.img?.[0]?.url,
    }));
}
