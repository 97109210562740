/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Conflict error on purchase_* methods
 */
export interface PurchaseConflictError { 
    error_key?: PurchaseConflictError.ErrorKeyEnum;
}
export namespace PurchaseConflictError {
    export type ErrorKeyEnum = 'wallet.items_limit_reached' | 'wallet.orders_limit_reached';
    export const ErrorKeyEnum = {
        ItemsLimitReached: 'wallet.items_limit_reached' as ErrorKeyEnum,
        OrdersLimitReached: 'wallet.orders_limit_reached' as ErrorKeyEnum
    };
}


