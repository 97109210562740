/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetOfferPaymentMethods410Response { 
    error_key?: GetOfferPaymentMethods410Response.ErrorKeyEnum;
}
export namespace GetOfferPaymentMethods410Response {
    export type ErrorKeyEnum = 'promocode.inactive';
    export const ErrorKeyEnum = {
        PromocodeInactive: 'promocode.inactive' as ErrorKeyEnum
    };
}


