import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { from, Observable } from 'rxjs';
import { scrypt } from 'scrypt-js';
import { ScryptParams } from '@api/backend';

@Injectable({
  providedIn: 'root',
})
export class ScryptService {

  public scrypt(challenge: ScryptParams): Observable<string> {
    const password = Buffer.from(challenge.input.normalize('NFKC'), 'utf-8');
    const salt = Buffer.from(challenge.salt.normalize('NFKC'), 'utf-8');
    return from(scrypt(password, salt, challenge.N, challenge.r, challenge.p, challenge.dklen)
      .then((uint8Arr: Uint8Array) => Buffer.from(uint8Arr, uint8Arr.byteOffset, uint8Arr.byteLength).toString('hex')));
  }
}
