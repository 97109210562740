import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

import { RippleDirective } from '@shared/directives';

type ButtonVariant = 'primary' | 'secondary' | 'secondary-reverse' | 'interactive' | 'outlined';

@Component({
  selector: 'cheelee-button',
  standalone: true,
  imports: [CommonModule, RippleDirective],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements AfterViewInit {
  @Input()
  public variant: ButtonVariant = 'primary';

  @Input({ transform: booleanAttribute })
  public isDisabled = false;

  @Input({ transform: booleanAttribute })
  public withFullWidth = false;

  @Input({ transform: booleanAttribute })
  public isSmall = false;

  @Input({ transform: booleanAttribute })
  public isRoundShape = false;

  @Input({ transform: booleanAttribute })
  public withAnimatedBorder = false;

  @Input()
  public customBackgroundColor?: string;

  @Input()
  public customColor?: string;

  @Input()
  public inlineStyle?: string;

  @Output()
  public clicked = new EventEmitter<MouseEvent>();

  private hostRef = inject(ElementRef);

  private renderer = inject(Renderer2);

  public ngAfterViewInit(): void {
    if (this.withFullWidth) {
      this.renderer.setStyle(this.hostRef.nativeElement, 'width', '100%');
    }
  }

  public onClick(event: MouseEvent): void {
    if (this.isDisabled) {
      return;
    }

    this.clicked.emit(event);
  }
}
