export const EMPTY_STRING = '';

export enum HttpKeys {
  Forbidden = 'Forbidden',
  HttpAuthorizationExpiredKey = 'authorization.expired',
  HttpAuthorizationRequiredKey = 'authorization.required',
  HttpAuthorizationInvalid = 'authorization.invalid',
  HttpAuthorizationSessionNotFound = 'session_not_found',
  PromocodeNotFound = 'promocode.not_found',
  OrderLimitsReached = 'wallet.orders_limit_reached',
}

export enum SocialAuthClientIds {
  Google = '684592764731-3i0bkm24f5kttm9s7f6bu3nnv6ihifmg.apps.googleusercontent.com',
  Vk = '51492665',
  Facebook = '464173252495149',
  Apple = 'io.cheelee.web',
}

export enum Breakpoints {
  Mobile = '(max-width: 574.98px)',
  Tablet = '(min-width: 575px) and (max-width: 1091.98px)',
  Desktop = '(min-width: 1092px)',
}

export enum ModalsNames {
  Auth = 'Auth Dialog',
  Confirm = 'Confirm Modal',
  Curtain = 'Curtain',
  GetApp = 'Get App Dialog',
  Qr = 'Qr Dialog',
  UserMedia = 'UserMedia Dialog',
  FeedMedia = 'FeedMedia Dialog',
  SingleMedia = 'SingleMedia Dialog',
  AvatarLoading = 'Avatar Loading Dialog',
  UsersList = 'Users List Dialog',
  ShareMedia = 'Share media',
  ShareUser = 'Share user',
  Select = 'Select Dialog',
}

export const DIALOG_ANIMATION_CLASSES = {
  leftToRight: 'nsm-dialog-animation-ltr',
  fade: 'fade-animation',
  mobileFullscreen: 'mobile-fullscreen',
};

export const BREAKPOINT_VALUES = Object.values(Breakpoints);

export const GLOBAL_ERROR_CODES = [ /*404*/ ]; // нужен для обработки промокодов
export const LOCAL_ERROR_CODES = [400, 403, 404, 406, /*409, */422, 429, 500];
export const SPECIAL_CODES = [...GLOBAL_ERROR_CODES, ...LOCAL_ERROR_CODES];
// export const EXTERNAL_ERROR_CODES_FN = (error: HttpErrorResponse) => {
//   const status = error?.status;
// };

export const COOKIE = {
  EXPIRE: 7,
  PATH: '/',
};

export const APP_AGE_RESTRICTION = 12; // years old

export const MAX_MEDIA_VIEW_COUNT = 5;

export enum NavigateMediaDirections {
  BACKWARD,
  FORWARD,
}
