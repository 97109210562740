import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { RouterNavigateConfig } from '@core/models';

export const routerActions = createActionGroup({
  source: 'Router',
  events: {
    Navigate: props<RouterNavigateConfig>(),
    'Navigate Success': emptyProps(),
    'Clear Router Params': emptyProps(),
    'Get Back': emptyProps(),
    'Get Back Success': emptyProps(),
  },
});
