import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  numberAttribute,
  Output,
  signal,
  ViewChild,
} from '@angular/core';

import { CodeInputComponent as CodeInput, CodeInputModule } from 'angular-code-input';

@Component({
  selector: 'cheelee-code-input',
  standalone: true,
  imports: [CommonModule, CodeInputModule],
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeInputComponent {
  @Input({ transform: numberAttribute })
  public length = 6;

  @Input({ transform: booleanAttribute })
  public isDisabled = false;

  @Output()
  public codeCompleted = new EventEmitter<string>();

  @ViewChild('codeInput')
  public codeInput!: CodeInput;

  @Input({ transform: booleanAttribute })
  public set hasErrors(hasError: boolean) {
    this.canShowError.set(hasError);

    if (hasError) {
      this.focusOnLastFieldWithValue();
    }
  }

  public canShowError = signal(false);

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  public onCodeChanged(): void {
    if (!this.canShowError()) {
      return;
    }

    this.canShowError.set(false);
  }

  public onCodeCompleted(code: string): void {
    this.codeCompleted.emit(code);
  }

  private focusOnLastFieldWithValue(): void {
    const fieldsWithValue = this.elementRef.nativeElement.querySelectorAll('input.has-value');

    if (fieldsWithValue.length > 0) {
      const lastField = fieldsWithValue[fieldsWithValue.length - 1] as HTMLInputElement;
      setTimeout(() => lastField.focus());
    }
  }
}
