/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurchaseHistoryOrderOfferProduct } from './purchaseHistoryOrderOfferProduct';
import { Image } from './image';
import { OfferPrice } from './offerPrice';
import { AmountNullable } from './amountNullable';


export interface PurchaseHistoryOrderOffer { 
    id?: string;
    price?: OfferPrice;
    image?: Image;
    /**
     * Short name of the product
     */
    title?: string;
    product?: PurchaseHistoryOrderOfferProduct;
    reward?: AmountNullable | null;
}

