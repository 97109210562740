/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OfferPrice { 
    /**
     * Upper case name of the currency
     */
    currency?: OfferPrice.CurrencyEnum;
    /**
     * Initial price of the offer
     */
    initial?: string;
    /**
     * Price value to show to the user. Will be used during purchase
     */
    _final?: string;
    /**
     * Discount percent. 0-100
     */
    discount_percent?: string | null;
}
export namespace OfferPrice {
    export type CurrencyEnum = 'USD' | 'LEE';
    export const CurrencyEnum = {
        Usd: 'USD' as CurrencyEnum,
        Lee: 'LEE' as CurrencyEnum
    };
}


