@let mediaItem = media();
@let userVideoItem = userVideo();
@let adItem = ad();

@if (mediaItem) {
  <ng-container *ngTemplateOutlet="actionsTpl; context: {
    isMedia: true,
    mediaId: mediaItem.media_id,
    mediaName: mediaItem.media_name,
    author: mediaItem.author,
    isLiked: mediaItem.is_liked,
    likes: mediaItem.counters.likes,
    comments: mediaItem.counters.comments,
    reposts: mediaItem.counters.reposts,
  }"></ng-container>
} @else if (userVideoItem) {
  @let author = userVideoItem.author;
  <ng-container *ngTemplateOutlet="actionsTpl; context: {
    isMedia: true,
    mediaId: userVideoItem.id,
    author: {
     user_avatar_url: author?.username ?? '',
     is_deleted: false,
     user_id: author?.id ?? '',
     is_identity_confirmed: !!author?.is_verified
    },
    isLiked: !!userVideoItem.likes?.is_liked,
    likes: userVideoItem.likes?.count,
    comments: userVideoItem.comments?.count,
    reposts: userVideoItem.share?.count,
  }"></ng-container>
} @else if (adItem) {
  <ng-container *ngTemplateOutlet="actionsTpl; context: { isMedia: false }"></ng-container>
}

<ng-template
  #actionsTpl
  let-isMedia="isMedia"
  let-mediaId="mediaId"
  let-mediaName="mediaName"
  let-author="author"
  let-isLiked="isLiked"
  let-likes="likes"
  let-comments="comments"
  let-reposts="reposts"
>
  <div class="actions" >
    @if (isMedia) {
      <cheelee-avatar
        class="actions__avatar"
        [user]="author"
        withBorder
        [analytics]="analyticsEvents.MediaUserTap"
      />
      <div class="actions__btn-wrapper">
        <button
          class="actions__btn"
          (cheeleeThrottleClick)="openGetAppDialog(analyticsEvents.MediaLikeTap, 'share')"
          [analytics]="analyticsEvents.MediaLikeTap"
        >
          <div
            class="actions__like-icon"
            [ngClass]="{ active: isLiked, 'like-animation': withLikeAnimation() }"
          ></div>
        </button>
        <span class="actions__counter bold">{{ likes | shortNumber }}</span>
      </div>

      <div class="actions__btn-wrapper">
        <button
          class="actions__btn"
          cheeleeRipple
          isCenteredRipple
          (click)="openGetAppDialog(analyticsEvents.MediaCommentTap)"
          [analytics]="analyticsEvents.MediaCommentTap"
        >
          <cheelee-icon
            iconId="comments-icon"
            size="44"
          />
        </button>
        <span class="actions__counter bold">{{ comments | shortNumber }}</span>
      </div>

      <div class="actions__btn-wrapper">
        <button
          class="actions__btn"
          cheeleeRipple
          isCenteredRipple
          (click)="openShareCurtain(mediaId, mediaName)"
          [analytics]="analyticsEvents.MediaShareTap"
        >
          <cheelee-icon
            iconId="share-icon"
            size="44"
          />
        </button>
        <span class="actions__counter bold">{{ reposts | shortNumber }}</span>
      </div>
    }

    <div
      class="actions__btn-wrapper"
      [style.margin-top.px]="10"
    >
      <button
        class="actions__btn"
        cheeleeRipple
        isCenteredRipple
        (click)="openGetAppDialog(analyticsEvents.MediaMenuTap, 'share')"
        [analytics]="analyticsEvents.MediaMenuTap"
      >
        <cheelee-icon
          iconId="more-icon"
          size="24"
        />
      </button>
    </div>
  </div>
</ng-template>
