import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  numberAttribute,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { tap } from 'rxjs';

import { filterNil } from '@core/helpers';

@Component({
  selector: 'cheelee-symbol-counter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './symbol-counter.component.html',
  styleUrls: ['./symbol-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymbolCounterComponent implements OnInit {
  @Input({ required: true })
  public control!: FormControl<string | null>;

  @Input({ required: true, transform: numberAttribute })
  public maxSymbols!: number;

  public symbolsCount = signal(0);

  private prevText = '';

  private readonly destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.symbolsCount.set(this.control.value?.length ?? 0);

    this.control.valueChanges
      .pipe(
        filterNil(),
        tap((value) => this.symbolsCount.set(value?.length ?? 0)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((text) => {
        if (text?.length > this.maxSymbols) {
          this.control.setValue(this.prevText);
        } else {
          this.prevText = text;
        }
      });
  }
}
