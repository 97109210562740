import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceDisplay',
  standalone: true,
})
export class PricePipe implements PipeTransform {
  transform(price: number | string | null, currency?: string | null): string {
    const notEmptyPrice = price ?? 0;
    switch (currency) {
      case 'USDT':
      case 'USD':
        return `$${ notEmptyPrice }`;
      case undefined:
        return `${ notEmptyPrice }`;
      default:
        return `${ notEmptyPrice } ${ currency }`;
    }
  }
}
