import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RouterPathParams } from '@core/models';

const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('@modules/media/media.module').then((m) => m.MediaModule),
  },
  {
    path: 'market',
    loadChildren: () => import('@modules/market/market.routes').then(m => m.MarketRoutes),
  },
  {
    path: `users/:${ RouterPathParams.UserId }`,
    loadChildren: () => import('@modules/user/user.module').then((m) => m.UserModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      initialNavigation: 'enabledBlocking',
      bindToComponentInputs: true,
    }),
  ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {
}
