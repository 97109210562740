import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { authActions } from '@store/auth/auth.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthRepository, UsersRepository } from '@core/repositories';
import { switchMap } from 'rxjs/operators';
import {
  AuthOAuthTypes, AuthPhoneTypes,
  AuthSteps,
  AuthTypes,
  ErrorResponse,
  SessionEmailData,
  SessionPhoneData,
  SessionTokenData,
} from '@core/models';
import { ScryptService } from '@core/services/scrypt/scrypt.service';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { authSelectors } from '@store/auth/auth.selectors';
import { OverlayService } from '@core/services';
import { DOCUMENT } from '@angular/common';
import { routerActions } from '@store/router';
import { OAuthLoginResponse } from '@api/backend';

@Injectable({ providedIn: 'root' })
export class AuthEffects {

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private actions: Actions,
    private store: Store,
    private authRepository: AuthRepository,
    private usersRepository: UsersRepository,
    private scryptService: ScryptService,
    private overlayService: OverlayService,
  ) {}

  public getAuthAvailable = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.getAvailable),
      concatLatestFrom(() => this.store.select(authSelectors.selectAvailable)),
      switchMap(([, availableOAuthTypes]) => {
        return !!availableOAuthTypes
          ? of(authActions.updateState({ value: { loading: false } }))
          : this.authRepository.getAuthAvailable().pipe(
            map((available) => authActions.getAvailableSuccess({ available })),
            catchError((error: unknown) => of(authActions.getAvailableFailure({ error: error as ErrorResponse }))),
          );
      }),
    );
  });

  public getCode$ = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.getCode),
      map(() => authActions.getScryptChallenge()),
    );
  });

  public getScryptChallenge$ = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.getScryptChallenge),
      switchMap(() =>
        this.authRepository.getAuthChallenge().pipe(
          map((challenge) => authActions.getScryptChallengeSuccess({ challenge: challenge.data })),
          catchError((error: unknown) => of(authActions.getScryptChallengeFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  });

  public getScryptChallengeSuccess$ = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.getScryptChallengeSuccess),
      switchMap(({ challenge }) =>
        this.scryptService.scrypt(challenge).pipe(
          map((hash) => authActions.getScryptHashSuccess({ hash })),
          catchError((error: unknown) => of(authActions.getScryptHashFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  });

  public getScryptHashSuccess$ = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.getScryptHashSuccess),
      concatLatestFrom(() => [this.store.select(authSelectors.selectAuthState)]),
      switchMap(([, { auth_phone_type, auth_type, hash, phone_number, email }]) => {
        const handler$: Observable<SessionPhoneData | SessionEmailData> =
          auth_type === AuthTypes.PHONE
            ? this.authRepository.postAuthPhone({
              auth_type: auth_phone_type,
              hash: String(hash),
              phone_number: String(phone_number),
            })
            : this.authRepository.postAuthEmail({ hash: String(hash), email: String(email) });

        return handler$.pipe(
          map(() => authActions.getCodeSuccess()),
          catchError((error: unknown) => of(authActions.getCodeFailure({ error: error as ErrorResponse }))),
        );
      }),
    );
  });

  public verifyCode$ = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.verifyCode),
      concatLatestFrom(() => [this.store.select(authSelectors.selectAuthState)]),
      switchMap(([, { auth_type, code, phone_number, email, auth_oauth_type, auth_phone_type }]) => {
        let handler$: Observable<SessionTokenData>;

        switch (auth_type) {
          case AuthTypes.PHONE:
            handler$ = this.authRepository.postAuthPhoneVerify({
              code: String(code),
              phone_number: String(phone_number),
              auth_type: auth_phone_type as AuthPhoneTypes,
            });
            break;

          case AuthTypes.EMAIL:
            handler$ = this.authRepository.postAuthEmailVerify({
              code: String(code),
              email: String(email),
            });
            break;

          default:
            handler$ = this.getOAuthCallbackMethod(String(code), auth_oauth_type);
            break;
        }

        return handler$.pipe(
          map(({ data }) =>
            authActions.verifyCodeSuccess({
              token: data,
              step: data.required_2fa ? AuthSteps.PASSWORD : AuthSteps.DONE,
            }),
          ),
          catchError((error: unknown) => of(authActions.verifyCodeFailure({ error: error as ErrorResponse }))),
        );
      }),
    );
  });

  public verifyCodeSuccess$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(authActions.verifyCodeSuccess),
        concatLatestFrom(() => [this.store.select(authSelectors.selectAuthState)]),
      );
    },
    { dispatch: false },
  );

  public verifyCodeFailure$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(authActions.verifyCodeFailure),
        concatLatestFrom(() => [this.store.select(authSelectors.selectAuthState)]),
        tap(([, { auth_type }]) => {
          if (auth_type === AuthTypes.EMAIL) {
          }
        }),
      );
    },
    { dispatch: false },
  );

  public verifyPassword$ = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.verifyPassword),
      concatLatestFrom(() => [this.store.select(authSelectors.selectAuthState)]),
      switchMap(([, { password }]) =>
        this.usersRepository.postUsers2faVerify({ password: String(password) }).pipe(
          map((verifyData) => authActions.verifyPasswordSuccess({ id_2fa: verifyData.data.id_2fa })),
          catchError((error: unknown) => of(authActions.verifyPasswordFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  });

  public verifyPasswordSuccess$ = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.verifyPasswordSuccess),
      concatLatestFrom(() => [this.store.select(authSelectors.selectAuthState)]),
      switchMap(([, { auth_type, id_2fa, code, phone_number, email, auth_oauth_type, auth_phone_type }]) => {
        let handler$: Observable<SessionTokenData>;

        if (auth_type === AuthTypes.PHONE) {
          handler$ = this.authRepository.postAuthPhoneVerify(
            {
              code: String(code),
              phone_number: String(phone_number),
              auth_type: auth_phone_type as AuthPhoneTypes,
            },
            id_2fa,
          );
        } else if (auth_type === AuthTypes.EMAIL) {
          handler$ = this.authRepository.postAuthEmailVerify(
            {
              code: String(code),
              email: String(email),
            },
            id_2fa,
          );
        } else {
          handler$ = this.getOAuthCallbackMethod(String(code), auth_oauth_type, id_2fa);
        }

        return handler$.pipe(
          map((tokenData: SessionTokenData) => authActions.verifyUser2faSuccess({ token: tokenData.data })),
          catchError((error: unknown) => of(authActions.verifyUser2faFailure({ error: error as ErrorResponse }))),
        );
      }),
    );
  });

  public getOAuthLogin = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.getOAuthLogin),
      switchMap((payload) =>
        this.getOAuthLoginMethod(payload.social).pipe(
          map((oauth) => oauth.data.redirect_url),
          map((redirect_url) => {
            this.document.defaultView?.open(redirect_url, '_blank');
            return authActions.getOAuthLoginSuccess({ redirect_url });
          }),
          catchError((error: unknown) => of(authActions.getOAuthLoginFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  });

  public getOAuthCallback = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.getOAuthCallback),
      tap(() => routerActions.clearRouterParams()),
      map(({ code }) => authActions.verifyCode({ code })),
    );
  });

  public openAuthDialog = createEffect(
    () => {
      return this.actions.pipe(
        ofType(authActions.openAuthDialog),
        tap(() => this.overlayService.openAuthDialog()),
      );
    },
    { dispatch: false },
  );

  private getOAuthCallbackMethod(code: string, social?: string, id_2fa?: string): Observable<SessionTokenData> {
    switch (social) {
      case AuthOAuthTypes.VK:
        return this.authRepository.getAuthOAuthVkCallback(code, id_2fa);

      case AuthOAuthTypes.GOOGLE:
        return this.authRepository.getAuthOAuthGoogleCallback(code, id_2fa);

      case AuthOAuthTypes.APPLE:
        return this.authRepository.getAuthOAuthAppleCallback(code, id_2fa);

      case AuthOAuthTypes.FACEBOOK:
        return this.authRepository.getAuthOAuthFacebookCallback(code, id_2fa);

      default:
        return this.authRepository.getAuthOAuthGoogleCallback(code, id_2fa);
    }
  }

  private getOAuthLoginMethod(social: AuthOAuthTypes): Observable<OAuthLoginResponse> {
    switch (social) {
      case AuthOAuthTypes.VK:
        return this.authRepository.getAuthOAuthVkLogin();

      case AuthOAuthTypes.GOOGLE:
        return this.authRepository.getAuthOAuthGoogleLogin();

      case AuthOAuthTypes.APPLE:
        return this.authRepository.getAuthOAuthAppleLogin();

      case AuthOAuthTypes.FACEBOOK:
        return this.authRepository.getAuthOAuthFacebookLogin();

      default:
        return this.authRepository.getAuthOAuthGoogleLogin();
    }
  }
}
