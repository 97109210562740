export * from './auth.service';
import { AuthService } from './auth.service';
export * from './challenges.service';
import { ChallengesService } from './challenges.service';
export * from './class2FA.service';
import { Class2FAService } from './class2FA.service';
export * from './comments.service';
import { CommentsService } from './comments.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './market.service';
import { MarketService } from './market.service';
export * from './media.service';
import { MediaService } from './media.service';
export * from './mediaComments.service';
import { MediaCommentsService } from './mediaComments.service';
export * from './mediaEndpoints.service';
import { MediaEndpointsService } from './mediaEndpoints.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [AuthService, ChallengesService, Class2FAService, CommentsService, DefaultService, MarketService, MediaService, MediaCommentsService, MediaEndpointsService, UserService, UsersService];
