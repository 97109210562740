/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PurchaseInternal403Response { 
    error_key?: PurchaseInternal403Response.ErrorKeyEnum;
}
export namespace PurchaseInternal403Response {
    export type ErrorKeyEnum = 'user.blocked' | 'user.deleted' | 'promocode.prohibited';
    export const ErrorKeyEnum = {
        UserBlocked: 'user.blocked' as ErrorKeyEnum,
        UserDeleted: 'user.deleted' as ErrorKeyEnum,
        PromocodeProhibited: 'promocode.prohibited' as ErrorKeyEnum
    };
}


