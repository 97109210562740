import { Signal } from '@angular/core';
import { OfferPrice } from '@api/backend';
import { MarketOffer, MarketOfferDescription } from '@core/models/marketplace.models';

export abstract class MarketOfferUtils {

  public abstract offer: Signal<MarketOffer | null>;

  /**
   * Возвращает id.
   */
  public get id(): string | null {
    return this.offer()?.id || null;
  }


  /**
   * Возвращает тип товара.
   */
  public get productType(): string | null {
    return this.offer()?.product?.type || null;
  }

  /**
   * Возвращает процент скидки в формате `'-{percent}%'`, если он задан.
   */
  public get discountPercent(): string | null {
    const discount = parseFloat(this.offer()?.price?.discount_percent || '');
    return !isNaN(discount) && discount > 0 ? `-${discount}%` : null;
  }


  /**
   * Возвращает процент скидки в виде числа (RAW), если он задан.
   */
  public get rawDiscountPercent(): number {
    const discount = parseFloat(this.offer()?.price?.discount_percent || '');
    return !isNaN(discount) ? discount : 0;
  }

  /**
   * Возвращает начальную цену (initial price).
   */
  public get initialPrice(): number | null {
    const price = parseFloat(this.offer()?.price?.initial || '');
    return !isNaN(price) ? price : null;
  }

  /**
   * Возвращает финальную цену (final price).
   */
  public get finalPrice(): number | null {
    const price = parseFloat(this.offer()?.price?.final || '');
    return !isNaN(price) ? price : null;
  }

  /**
   * Возвращает цвет редкости без '0x'.
   */
  public get rarityColor(): string | null {
    const productObject = this.offer()?.product?.object;
    if (productObject && 'rarity' in productObject && productObject.rarity) {
      return productObject.rarity.color?.replace(/^0x/, '#') || null;
    }
    return null;
  }

  /**
   * Возвращает название редкости.
   */
  public get rarityName(): string | null {
    const productObject = this.offer()?.product?.object;
    if (productObject && 'rarity' in productObject && productObject.rarity) {
      return productObject.rarity.name ?? null;
    }
    return null;
  }

  /**
   * Возвращает валюту предложения.
   */
  public get currency(): OfferPrice.CurrencyEnum | null {
    return this.offer()?.price?.currency || null;
  }

  /**
   * Возвращает URL изображения товара.
   */
  public get imageUrl(): string | null {
    return this.offer()?.image?.img?.[0]?.url || null;
  }

  /**
   * Возвращает количество доступных единиц товара.
   */
  public get count(): number | null {
    const offer = this.offer();
    return offer?.count !== undefined && offer.count !== null ? offer.count : null;
  }

  /**
   * Возвращает заголовок товара.
   */
  public get title(): string | null {
    return this.offer()?.title || null;
  }

  /**
   * Возвращает краткое описание товара.
   */
  public get summary(): string | null {
    return this.offer()?.summary || null;
  }

  /**
   * Возвращает полное описание товара.
   */
  public get description(): string | null {
    return this.offer()?.description || null;
  }

  /**
   * Возвращает описание товара в виде массива объектов MarketOfferDescription.
   */
  public get parsedDescription(): MarketOfferDescription[] | null {
    const description = this.offer()?.description;
    if (!description) {
      return null;
    }
    return this.parseDescriptionText(description);
  }

  private isFootnote(text: string): boolean {
    // Регулярное выражение: строка начинается с *, затем любые символы,
    // но количество символов * в строке должно быть четным
    const pattern = /^\*([^*]*\*[^*]*\*)*[^*]*$/;
    return pattern.test(text);
  }

  /**
   * Парсит текст описания в массив объектов.
   */
  private parseDescriptionText(description: string): MarketOfferDescription[] {
    return description.split('\n').map(line => {
      const trimmedLine = line.trim();
      if (!trimmedLine) return null;
      if (trimmedLine.startsWith('• ')) {
        return { text: trimmedLine.slice(2).trim(), type: 'list' };
      } else if (this.isFootnote(trimmedLine)) {
        return { text: trimmedLine.slice(1).trim(), type: 'note' };
      } else {
        return { text: trimmedLine, type: 'main' };
      }
    }).filter(item => item !== null) as MarketOfferDescription[];
  }

  /**
   * Возвращает дату истечения срока действия.
   */
  public get expiredAt(): string | null {
    return this.offer()?.expires_at || null; // '2025-01-31T15:47:57.167Z';
  }
}
