/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurchaseHistoryOrderPaymentMethodObject } from './purchaseHistoryOrderPaymentMethodObject';


export interface PurchaseHistoryOrderPaymentMethod { 
    type?: PurchaseHistoryOrderPaymentMethod.TypeEnum;
    object?: PurchaseHistoryOrderPaymentMethodObject;
}
export namespace PurchaseHistoryOrderPaymentMethod {
    export type TypeEnum = 'apple' | 'google' | 'internal' | 'external';
    export const TypeEnum = {
        Apple: 'apple' as TypeEnum,
        Google: 'google' as TypeEnum,
        Internal: 'internal' as TypeEnum,
        External: 'external' as TypeEnum
    };
}


