import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[cheeleeAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  @Input()
  public set cheeleeAutofocus(withFocus: boolean | undefined) {
    if (withFocus !== undefined) {
      this.isAutofocusActive = withFocus;
    }
  }

  public isAutofocusActive!: boolean;

  constructor(private host: ElementRef<HTMLInputElement>) {}

  public ngAfterViewInit(): void {
    if (this.isAutofocusActive) {
      this.host.nativeElement.focus();
    }
  }
}
