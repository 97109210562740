/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VideoBitratesInner } from './videoBitratesInner';
import { VideoFrame1 } from './videoFrame1';


export interface Video { 
    scroll?: string | null;
    download_url?: string | null;
    frame1?: VideoFrame1;
    /**
     * Video file
     */
    bitrates?: Array<VideoBitratesInner>;
}

