/**
 * Public API for Marketplace
 * This is the public API for the Marketplace service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurchaseHistoryOrderOfferProductObject } from './purchaseHistoryOrderOfferProductObject';


export interface PurchaseHistoryOrderOfferProduct { 
    type?: PurchaseHistoryOrderOfferProduct.TypeEnum;
    object?: PurchaseHistoryOrderOfferProductObject;
}
export namespace PurchaseHistoryOrderOfferProduct {
    export type TypeEnum = 'common' | 'glasses' | 'lootbox';
    export const TypeEnum = {
        Common: 'common' as TypeEnum,
        Glasses: 'glasses' as TypeEnum,
        Lootbox: 'lootbox' as TypeEnum
    };
}


