<div
  class="text-input"
  [ngClass]="{
    'with-content': hasContent(),
    error: invalid()
  }"
  (click)="input.focus()"
>
  <div
    #contentWrapper
    class="text-input__content-wrapper"
  >
    <ng-content />
  </div>

  <input
    #input
    class="text-input__field input-text"
    [cheeleeAutofocus]="withAutofocus"
    [formControl]="control"
    [maxlength]="maxlength"
    [name]="name"
    [placeholder]="placeholder"
    [spellcheck]="false"
    [style.text-transform]="textCase"
    [type]="type"
    autocomplete="on"
    (keyup.enter)="enterPress.emit()"
  />

  <cheelee-symbol-counter
    class="text-input__counter"
    *ngIf="maxlength !== null && maxlength > 0"
    [control]="control"
    [maxSymbols]="maxlength"
  />

  <div class="text-input__reset-btn-wrapper">
    <button
      type="button"
      class="text-input__reset-btn"
      *ngIf="!isPasswordMode(); else resetBtn"
      [class.hidden]="!control.value?.length || control.disabled"
      cheeleeRipple
      isCenteredRipple
      (click)="resetValue()"
    >
      <cheelee-icon
        iconId="search-cancel-icon"
        size="24"
      />
    </button>

    <ng-template #resetBtn>
      <button
        class="text-input__reset-btn"
        [class.hidden]="control.disabled"
        cheeleeRipple
        isCenteredRipple
        (click)="toggleShowText()"
      >
        <cheelee-icon
          *ngIf="canShowPassword(); else eyeOff"
          iconId="eye-icon-off"
        />
        <ng-template #eyeOff>
          <cheelee-icon iconId="eye-icon" />
        </ng-template>
      </button>
    </ng-template>
  </div>
</div>
