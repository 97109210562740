/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentMethodPrice { 
    /**
     * Upper case name of the currency
     */
    currency?: PaymentMethodPrice.CurrencyEnum;
    /**
     * Price of the offer with fee included
     */
    total?: string;
    /**
     * Initial price of the offer via particular payment method
     */
    raw?: string;
    /**
     * Fee percent as a decimal. 0-100
     */
    fee_percent?: string;
}
export namespace PaymentMethodPrice {
    export type CurrencyEnum = 'USD' | 'LEE';
    export const CurrencyEnum = {
        Usd: 'USD' as CurrencyEnum,
        Lee: 'LEE' as CurrencyEnum
    };
}


