import { AppTypes } from '@core/models';

export interface Environment {
  production: boolean;
  base_url: string;
  market_base_url: string;
  cdn_url: string;
  name: string;
  app_name: string;
  app_version: string;
  app_build?: string;
  app_type: AppTypes;
  sentryClientDsn: string;
  fingerprintApiKey: string;
  ymConfig: {
    id: number;
  };
  redirectServiceUrl: string;
  firebaseConfig: {}
}
