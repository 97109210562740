/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OfferImageImgInner } from './offerImageImgInner';


/**
 * Image object with different sizes and blur hash
 */
export interface OfferImage { 
    /**
     * The blur hash for images
     */
    blur?: string | null;
    img?: Array<OfferImageImgInner>;
}

