import { Injectable, signal } from '@angular/core';

import { Store } from '@ngrx/store';

import { RouterNavigateConfig } from '@core/models';
import { routerActions, routerSelectors } from '@store/router';

@Injectable({ providedIn: 'root' })
export class RouterFacade {
  public readonly routerState = this.store.selectSignal(routerSelectors.selectRouterState);

  public readonly data = this.store.selectSignal(routerSelectors.selectData);

  public readonly params = this.store.selectSignal(routerSelectors.selectParams);

  public readonly url$ = this.store.select(routerSelectors.selectUrl);

  public readonly routerParams$ = this.store.select(routerSelectors.selectParams);

  public previousUrl = signal('');

  private currentUrl = signal('');

  constructor(private readonly store: Store) {
    this.url$.subscribe((url) => {
      this.previousUrl.set(this.currentUrl());
      this.currentUrl.set(url);
    });
  }

  public navigate(config: RouterNavigateConfig): void {
    this.store.dispatch(routerActions.navigate(config));
  }

  public clearRouterParams(): void {
    this.store.dispatch(routerActions.clearRouterParams());
  }
}
