/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetOffers422Response { 
    error_key?: GetOffers422Response.ErrorKeyEnum;
}
export namespace GetOffers422Response {
    export type ErrorKeyEnum = 'validation';
    export const ErrorKeyEnum = {
        Validation: 'validation' as ErrorKeyEnum
    };
}


