import {
  PaymentMethodApple,
  PaymentMethodExternal,
  PaymentMethodGoogle,
  PaymentMethodInternal,
} from '@api/backend';
import { SelectOption } from '@core/models/select.model';


export type MarketOfferPaymentMethod =
  MarketOfferPaymentMethodApple
  | MarketOfferPaymentMethodGoogle
  | MarketOfferPaymentMethodExternal
  | MarketOfferPaymentMethodInternal;

export enum MarketOfferPaymentMethodType {
  APPLE = 'apple',
  GOOGLE = 'google',
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum MarketOfferPaymentMethodPriority {
  FEATURED = 'featured',
  COMMON = 'common',
}

export type MarketOfferPaymentMethodApple = {
  type: MarketOfferPaymentMethodType.APPLE,
  object: PaymentMethodApple,
};

export type MarketOfferPaymentMethodGoogle = {
  type: MarketOfferPaymentMethodType.GOOGLE,
  object: PaymentMethodGoogle,
};

export type MarketOfferPaymentMethodExternal = {
  type: MarketOfferPaymentMethodType.EXTERNAL,
  object: PaymentMethodExternal,
};

export type MarketOfferPaymentMethodInternal = {
  type: MarketOfferPaymentMethodType.INTERNAL,
  object: PaymentMethodInternal,
};

export type MarketOfferPaymentMethodPrice = NonNullable<PaymentMethodInternal['price']> & NonNullable<PaymentMethodExternal['price']>;

export function isMarketOfferPaymentMethodApple(
  method: MarketOfferPaymentMethod,
): method is MarketOfferPaymentMethodApple {
  return method.type === MarketOfferPaymentMethodType.APPLE;
}

export function isMarketOfferPaymentMethodGoogle(
  method: MarketOfferPaymentMethod,
): method is MarketOfferPaymentMethodGoogle {
  return method.type === MarketOfferPaymentMethodType.GOOGLE;
}

export function isMarketOfferPaymentMethodExternal(
  method: MarketOfferPaymentMethod,
): method is MarketOfferPaymentMethodExternal {
  return method.type === MarketOfferPaymentMethodType.EXTERNAL;
}

export function isMarketOfferPaymentMethodInternal(
  method: MarketOfferPaymentMethod,
): method is MarketOfferPaymentMethodInternal {
  return method.type === MarketOfferPaymentMethodType.INTERNAL;
}

export function paymentMethodId(method: MarketOfferPaymentMethod): string {
  return `${method.type}.${method.object.id}`;
}

export function mapPaymentMethodToSelectOption(method: MarketOfferPaymentMethod, usePrefix: boolean): SelectOption | null {
  if (isMarketOfferPaymentMethodApple(method)) {
    return ({
      label: 'Apple Pay',
      value: paymentMethodId(method),
      icon: method.object.image?.img?.[0].url,
      type: method.type,
    } as SelectOption);
  } else if (isMarketOfferPaymentMethodGoogle(method)) {
    return ({
      label: 'Google Pay',
      value: paymentMethodId(method),
      icon: method.object.image?.img?.[0].url,
      type: method.type,
    } as SelectOption);
  } else if (isMarketOfferPaymentMethodInternal(method)) {
    return ({
      label: method.object.price?.currency,
      value: paymentMethodId(method),
      icon: method.object.image?.img?.[0].url,
      type: method.type,
      labelPrefix: usePrefix && method.object.price?.fee_percent ? `${method.object.price.fee_percent}%` : undefined,
    } as SelectOption);
  } else if (isMarketOfferPaymentMethodExternal(method)) {
    return ({
      label: method.object.name ?? '',
      value: paymentMethodId(method),
      icon: method.object.image?.img?.[0].url,
      type: method.type,
      labelPrefix: usePrefix && method.object.price?.fee_percent ? `${method.object.price.fee_percent}%` : undefined,
    } as SelectOption);
  }
  return null;
}

export function mapPaymentMethodListToSelectOptions(list: MarketOfferPaymentMethod[], userPrefix = false): SelectOption[] {
  return list
    .map(method => mapPaymentMethodToSelectOption(method, userPrefix))
    .filter(Boolean) as SelectOption[];
}
