import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { CustomRouterStateSerializer } from '@store/router/router-state.serializer';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { RouterEffects } from '@store/router/router.effects';

const routerFeatureKey = 'router';

export const RouterProviders = [
  provideRouterStore({ serializer: CustomRouterStateSerializer }),
  provideState(routerFeatureKey, routerReducer),
  provideEffects(RouterEffects),
];
